import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendarDays, faCoins, faTruck } from '@fortawesome/pro-light-svg-icons';
import { LineItem } from '../../../../shared/src/line-item-types';
import { MediaBuyMetrics } from '../../metrics/metrics-types';
import { formatCurrency } from '../../components/table-utils';
import {
  formatNumber,
  formatPercentageNoDecimal,
  formatSpend,
  getElapsedDaysDisplay,
  getUnitName
} from '../../../../shared/src/line-item-utils';

type Props = {
  lineItem: LineItem;
  metrics: MediaBuyMetrics;
};

export function PerformanceRail({ lineItem, metrics }: Props) {
  const unitAbbr = getUnitName(lineItem.unit_price_type.name).abbr;
  const {
    elapsedDays,
    lengthInDays,
    elapsedProportion,
    unitsDeliveredProportion,
    targetUnitsDeliveredProportion,
    spendProportion,
    targetSpendProportion,
    adjustedUnitCost,
    adjustedTargetUnitCost
  } = metrics;

  return (
    <div className="flex flex-col gap-1 rounded-md bg-gray-200 p-1">
      {/* Schedule Elapsed */}
      <MetricSection>
        <MetricSectionHeader
          proportion={elapsedProportion}
          label="of schedule elapsed"
          icon={faCalendarDays}
        />
        <div className="my-3 text-sm">
          <span className="font-bold">{getElapsedDaysDisplay(elapsedDays, lengthInDays)}</span> of{' '}
          {lengthInDays} days passed
        </div>
        <div className="rounded bg-gray-200 p-1 pl-0 shadow-inner">
          <ProgressBar
            color="black"
            widthProportion={elapsedProportion <= 1 ? elapsedProportion : 1}
          />
        </div>
      </MetricSection>
      {/* Units Delivered */}
      <MetricSection>
        <MetricSectionHeader
          proportion={unitsDeliveredProportion}
          label={`of ${getUnitName(lineItem.unit_price_type.name).full} delivered`}
          icon={faTruck}
        />
        <ActualVsTargetProgressBars
          actualProportion={unitsDeliveredProportion}
          actualBarText={{ metric: formatNumber(metrics.deliveredUnits), unit: unitAbbr }}
          targetProportion={targetUnitsDeliveredProportion}
          targetBarText={{ metric: formatNumber(metrics.currentTargetUnits), unit: 'target' }}
          totalMetric={formatNumber(metrics.totalTargetUnits)}
          totalMetricUnit={`est. ${unitAbbr}`}
        />
      </MetricSection>
      {/* Media Budget Spent */}
      <MetricSection>
        <MetricSectionHeader
          proportion={spendProportion}
          label="of media budget spent"
          icon={faCoins}
        />
        <ActualVsTargetProgressBars
          actualProportion={spendProportion}
          actualBarText={{ metric: formatSpend(metrics.mediaSpend), unit: 'spent' }}
          targetProportion={targetSpendProportion}
          targetBarText={{ metric: formatSpend(metrics.currentPlannedSpend), unit: 'target' }}
          totalMetric={formatCurrency(metrics.totalPlannedSpend)}
          totalMetricUnit="budget"
        />
      </MetricSection>
      {/* Margin Performance */}
      <MetricSection>
        <div className="text-xl font-bold">Unit Cost</div>
        <ListedMetrics
          primaryMetric={`${formatCurrency(adjustedUnitCost)} Actual ${lineItem.unit_price_type.name}`}
          secondaryMetric={`${formatCurrency(adjustedTargetUnitCost)} Target ${lineItem.unit_price_type.name}`}
        />
      </MetricSection>
      {/* Cost Efficiency */}
      <MetricSection>
        <div className="text-xl font-bold">Net Revenue</div>
        <ListedMetrics
          primaryMetric={`${formatCurrency(metrics.revenue)} Net rev.`}
          secondaryMetric={`${formatCurrency(metrics.totalTargetRevenue)} Target net rev.`}
        />
      </MetricSection>
    </div>
  );
}
export function MetricSection({ children }: { children: React.ReactNode }) {
  return <div className="rounded bg-gray-100 p-[6px]">{children}</div>;
}

type MetricSectionHeaderProps = {
  proportion: number;
  label: string;
  icon: IconProp;
};

function MetricSectionHeader({ proportion, label, icon }: MetricSectionHeaderProps) {
  return (
    <div className="flex items-center justify-between text-base">
      <div>
        <span className={clsx('mr-1 text-xl font-bold', proportion === 0 && 'text-gray-400')}>
          {proportion === 0 ? '--' : formatPercentageNoDecimal(proportion)}
        </span>
        {label}
      </div>
      <FontAwesomeIcon icon={icon} size="lg" />
    </div>
  );
}

type ActualVsTargetProgressBarsProps = {
  actualProportion: number;
  actualBarText: ProgressBarText;
  targetProportion: number;
  targetBarText: ProgressBarText;
  totalMetric: string;
  totalMetricUnit: string;
};

function ActualVsTargetProgressBars({
  actualProportion,
  targetProportion,
  actualBarText,
  targetBarText,
  totalMetric,
  totalMetricUnit
}: ActualVsTargetProgressBarsProps) {
  const actualExceedsGoal = actualProportion > 1;
  return (
    <div className="relative mt-1 flex justify-between rounded bg-gray-200 p-1 pl-0 shadow-inner">
      <div className="flex flex-1 flex-col gap-0.5">
        <ProgressBar
          color={actualExceedsGoal ? 'red' : 'green'}
          widthProportion={actualExceedsGoal ? 1 : actualProportion}
          text={actualBarText}
        />
        <ProgressBar
          color="black"
          widthProportion={
            actualExceedsGoal ? (1 / actualProportion) * targetProportion : targetProportion
          }
          text={targetBarText}
        />
      </div>
      {actualProportion < 0.75 && targetProportion < 0.75 && (
        <div className="absolute right-1 self-center text-right text-sm">
          <div className="font-semibold">{totalMetric}</div>
          <div className="capitalize">{totalMetricUnit}</div>
        </div>
      )}
    </div>
  );
}

type ProgressBarText = {
  metric: string;
  unit: string;
};

type ProgressBarProps = {
  color: 'black' | 'green' | 'red';
  widthProportion: number;
  text?: ProgressBarText;
};

function ProgressBar({ widthProportion, text, color }: ProgressBarProps) {
  return (
    <div className="relative flex-1">
      <div
        className={clsx(
          'relative h-[24px] rounded-r-[4px]',
          getProgressBarColor(widthProportion, color)
        )}
        style={{ width: widthProportion < 0.01 ? '1%' : `${widthProportion * 100}%` }}
      />
      {text && (
        <div
          className={clsx(
            'absolute top-0.5 z-10 pl-1 text-sm capitalize',
            text.metric === '-' ? 'text-gray-400' : 'text-white'
          )}>
          {text.metric} {text.unit}
        </div>
      )}
    </div>
  );
}

function getProgressBarColor(width: number, color: ProgressBarProps['color']) {
  switch (color) {
    case 'black':
      return 'bg-gray-950';
    case 'green':
      return width === 0
        ? 'bg-gradient-to-r from-[#B2B2B2] to-[#737373]'
        : 'bg-gradient-to-r from-[#2DAD62] to-[#117A3C]';
    case 'red':
      return width === 0
        ? 'bg-gradient-to-r from-[#B2B2B2] to-[#737373]'
        : 'bg-gradient-to-r from-[#AD312D] to-[#7A1111]';
  }
}

type ListedMetricsProps = {
  primaryMetric: string;
  secondaryMetric: string;
};

function ListedMetrics({ primaryMetric, secondaryMetric }: ListedMetricsProps) {
  return (
    <div className="mt-1 flex flex-col gap-0.5 rounded bg-gray-200 p-1 text-sm">
      <div className="font-semibold">{primaryMetric}</div>
      <div>{secondaryMetric}</div>
    </div>
  );
}

import React, { ReactNode } from 'react';
import { LineItemsTable, LineItemTableData } from '../line-items/line-items-table';
import { useStore } from '../../store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PacingDrawer } from '../line-items/pacing-drawer';
import { Campaign } from 'shared/src/types';
import { formatDateString } from 'shared/src/date-utils';
import { faCopy, faList, faTrashCan, faUser } from '@fortawesome/pro-light-svg-icons';
import { useStrategyContext } from '../line-items/strategy-context';
import { CombinedStrategy } from '../../store/strategy-combiner';
import { ExpandableSidebarSection, PageContentSection, PageSection } from '../../components/layout';
import { DetailItem } from '../../components/detail-item';
import { useCampaignMetrics } from '../media-buys/use-media-buy-metrics';
import { calcCampaignMetrics, lineItemDeliveryMetrics } from '../../metrics/calculate-metrics';
import { CampaignHeroMetrics } from '../campaigns/campaign-hero-metrics';
import { formatCurrencyNoDecimal } from '../../components/table-utils';
import { getColorForZohoStage } from '../../utils/color-utils';
import { clsx } from 'clsx';
import { LineItemMetric } from 'shared/src/metrics-types';
import { campaignTitle, PageTitle } from '../shared/page-title';
import { showDuplicatedItemsToast } from '../../components/duplicated-items-toast';
import { LineItemDeleteModal } from '../line-items/line-item-delete-modal';
import { Tooltip } from 'react-tooltip';

export function StrategyOverview() {
  const { strategy, campaign } = useStrategyContext();
  const { data } = useCampaignMetrics(strategy.campaign_id);
  const metrics = calcCampaignMetrics(data, strategy.line_items);

  return (
    <>
      <PageTitle title={campaignTitle(campaign?.Deal_Name)} />
      <PageSection>
        <PageContentSection>
          <PerformanceHeader campaign={campaign} />
          {metrics && <CampaignHeroMetrics metrics={metrics} />}
          <LineItemsSection data={data} campaign={campaign} strategy={strategy} />
        </PageContentSection>
        <ExpandableSidebarSection
          sections={[
            {
              name: 'details',
              header: 'Details',
              icon: faList,
              render: () => <DetailsSection campaign={campaign} />
            },
            {
              name: 'users',
              header: 'Role Assignments',
              icon: faUser,
              render: () => <AssignmentsSection campaign={campaign} />
            }
          ]}
        />
      </PageSection>
      <PacingDrawer strategy={strategy} />
    </>
  );
}

type Props = {
  campaign: Campaign | undefined;
  strategy: CombinedStrategy;
  data: LineItemMetric[];
};

function LineItemsSection({ campaign, strategy, data }: Props) {
  const [selectedRows, setSelectedRows] = React.useState<LineItemTableData[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
  const duplicateLineItems = useStore(state => state.duplicateLineItems);
  const metrics = lineItemDeliveryMetrics(data, strategy.line_items);

  function handleDuplicate() {
    if (selectedRows.length > 0) {
      duplicateLineItems(selectedRows);
      selectedRows.length > 1 && showDuplicatedItemsToast(selectedRows.length);
    }
  }

  function handleKeyboardDuplicate(currentRow: LineItemTableData) {
    const otherRows = selectedRows.filter(row => row.id !== currentRow.id);
    duplicateLineItems([...otherRows, currentRow]);
  }

  function handleDelete() {
    if (selectedRows.length > 0) {
      setDeleteModalOpen(true);
    }
  }

  const disableDelete = selectedRows.some(item => item.media_buys.length > 0);

  return (
    <div className="flex h-full flex-col">
      <div className="flex items-center justify-between py-2">
        <div className="pr-16 text-lg font-bold">Line Items</div>
        {selectedRows.length > 0 && (
          <div className="flex">
            <LineItemBulkAction
              colorClasses="text-blue-600 hover:bg-blue-50"
              icon={<FontAwesomeIcon icon={faCopy} />}
              onClick={handleDuplicate}
              label="Duplicate"
            />
            <LineItemBulkAction
              colorClasses="text-red-600 hover:bg-red-50"
              disabled={disableDelete}
              icon={<FontAwesomeIcon icon={faTrashCan} />}
              onClick={handleDelete}
              label="Delete"
              tooltipId="delete-line-item-tooltip"
            />
            {disableDelete && (
              <Tooltip
                id="delete-line-item-tooltip"
                place="left-start"
                style={{
                  backgroundColor: '#111827',
                  borderRadius: '5px',
                  maxWidth: 215,
                  position: 'fixed',
                  zIndex: 1
                }}>
                <div className="text-sm">
                  <div className="font-semibold">
                    Cannot delete line items with existing Media Buys.
                  </div>
                  <div>One or more selected line items has media buys.</div>
                </div>
              </Tooltip>
            )}
          </div>
        )}
      </div>
      <LineItemsTable
        lineItems={strategy.line_items ?? []}
        mediaTraders={campaign?.team.mediaTraders}
        setSelectedRows={setSelectedRows}
        handleKeyboardDuplicate={handleKeyboardDuplicate}
        hideRowActions={selectedRows.length > 0}
        metrics={metrics}
      />
      <LineItemDeleteModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        lineItemsToDelete={selectedRows}
      />
    </div>
  );
}

type LineItemBulkActionProps = {
  colorClasses: string;
  disabled?: boolean;
  icon: ReactNode;
  onClick: () => void;
  label: string;
  tooltipId?: string;
};

function LineItemBulkAction({
  colorClasses,
  disabled = false,
  icon,
  onClick,
  label,
  tooltipId
}: LineItemBulkActionProps) {
  return (
    <div>
      <button
        data-tooltip-id={tooltipId}
        className={clsx(
          `flex items-center gap-2 rounded-md px-3 py-1 text-sm ${!disabled ? colorClasses : ''}`,
          disabled ? 'text-gray-400' : ''
        )}
        disabled={disabled}
        onClick={onClick}>
        {icon}
        <div>{label}</div>
      </button>
    </div>
  );
}

function DetailsSection({ campaign }: { campaign: Campaign | undefined }) {
  return (
    <div className="flex flex-col">
      <DetailItem label="CN Number" value={campaign?.Deal_Number} />
      <DetailItem label="Created on" value={formatDateString(campaign?.Created_Time)} />
      <DetailItem label="Start Date" value={formatDateString(campaign?.Flight_Date)} />
      <DetailItem label="End Date" value={formatDateString(campaign?.End_Date)} />
      <DetailItem label="Account" value={campaign?.Account_Name.name} />
      <DetailItem label="Agency" value={campaign?.Agency?.name || '-'} />
      <DetailItem label="Campaign Goals" value={campaign?.Goals_KPIs} />
      <DetailItem label="Campaign Stage" value={campaign?.Stage} />
      <DetailItem
        label="New Business"
        value={(campaign?.New_Business && (campaign?.New_Business ? 'Yes' : 'No')) || '-'}
      />
    </div>
  );
}

function AssignmentsSection({ campaign }: { campaign: Campaign | undefined }) {
  return (
    <div className="flex flex-col">
      <DetailItem label="Account Manager" value={campaign?.team.leadAccountManager?.name || null} />
      <DetailItem
        label="Senior Media Trader"
        value={campaign?.team.seniorMediaTrader?.name || null}
      />
      <DetailItem
        label="Client Success Directors"
        value={campaign?.team.clientSuccessDirector?.name}
      />
      <DetailItem label="Analyitcs" value={campaign?.team.analytics?.name || null} />
      <DetailItem
        label="Ad Operations"
        value={campaign?.team.adOps.map(mt => mt.name).join(', ') || null}
      />
      <DetailItem
        label="Media Traders"
        value={campaign?.team.mediaTraders.map(mt => mt.name).join(', ') || null}
      />
    </div>
  );
}

export function PerformanceHeader({ campaign }: { campaign: Campaign | undefined }) {
  const price = campaign?.Budget ? formatCurrencyNoDecimal(campaign?.Budget) : undefined;

  return (
    <div className="mb-2 flex items-center">
      <div className="mr-8 text-lg font-bold">Campaign Performance</div>
      <div className="mr-4 flex items-center rounded-xl bg-gray-100 p-2 text-sm">
        <div
          className={clsx(
            'mr-2 h-[12px] w-[12px] rounded-full ',
            getColorForZohoStage(campaign?.Stage)
          )}
        />
        <div className="text-sm">{campaign?.Stage}</div>
      </div>
      <div className="flex items-center rounded-xl bg-gray-100 p-2 text-sm">
        <div className="mr-1">Price:</div>
        <div className="font-light">{price}</div>
      </div>
    </div>
  );
}

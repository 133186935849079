import React from 'react';
import { getElapsedDaysDisplay, getUnitName } from 'shared/src/line-item-utils';
import { Campaign } from 'shared/src/types';
import {
  DateItem,
  RightRailItem,
  RightRailSection,
  ViewPacingSchedule
} from '../../components/right-rail-components';
import { LineItem } from '../../../../shared/src/line-item-types';
import {
  formatCurrency,
  formatCurrencyNoDecimal,
  formatPacingType,
  formatUnitCost
} from '../../components/table-utils';
import { MediaBuyMetrics } from '../../metrics/metrics-types';

type Props = {
  campaign: Campaign | undefined;
  lineItem: LineItem;
  metrics: MediaBuyMetrics;
  setExpanded: (expanded: string) => void;
};

export function DetailsRail({ campaign, lineItem, setExpanded, metrics }: Props) {
  return (
    <div className="flex flex-col gap-1 rounded-md bg-gray-200 p-1">
      <RightRailSection>
        <RightRailItem
          label="Assigned Media Trader"
          value={lineItem.media_traders?.map(mt => mt.name).join(', ')}
        />
      </RightRailSection>
      <RightRailSection>
        <div className="flex grow flex-col gap-0.5">
          <DateItem label="Start Date" date={metrics.startDate} />
        </div>
        <div className="flex grow flex-col gap-0.5">
          <DateItem label="End Date" date={metrics.endDate} />
        </div>
        <div className="flex grow-[2] flex-col gap-0.5">
          <RightRailItem
            label="Days"
            value={getElapsedDaysDisplay(metrics.elapsedDays, metrics.lengthInDays)}
          />
        </div>
      </RightRailSection>
      <RightRailSection>
        <div className="flex flex-1 flex-col gap-0.5">
          <RightRailItem
            label="Channel : Tactic"
            value={`${lineItem.channel?.name} : ${lineItem.tactic?.name}`}
          />
          <RightRailItem
            label="Platforms"
            value={lineItem.media_platforms?.map(platform => platform.name).join(', ')}
          />
        </div>
        <div className="flex flex-1 flex-col gap-0.5">
          <RightRailItem label="Price Type" value={lineItem.unit_price_type?.name} />
          <RightRailItem
            label="Estimated Units"
            value={`${metrics.totalTargetUnits.toLocaleString()}${lineItem.unit_price_type && ` ${getUnitName(lineItem.unit_price_type.name).full}`}`}
          />
        </div>
      </RightRailSection>
      <RightRailSection>
        <div className="flex flex-1 flex-col gap-0.5">
          <RightRailItem label="Price" value={formatCurrencyNoDecimal(metrics.price)} />
          <RightRailItem
            label="Unit Price"
            value={
              <span>
                {formatUnitCost(metrics.adjustedUnitPrice)}
                {lineItem.unit_price_type && (
                  <span className="text-xs font-normal"> {lineItem.unit_price_type.name}</span>
                )}
              </span>
            }
          />
        </div>
        <div className="flex flex-1 flex-col gap-0.5">
          <RightRailItem
            label="Target Margin"
            value={`${parseFloat((metrics.targetMargin * 100).toFixed(2)).toString()}%`}
          />
        </div>
        <div className="flex flex-1 flex-col gap-0.5">
          <RightRailItem label="Media Budget" value={formatCurrency(metrics.budget)} />
          <RightRailItem
            label="Target Unit Cost"
            value={
              <span>
                {formatCurrency(metrics.adjustedUnitCost)}
                {lineItem.unit_price_type && (
                  <span className="text-xs font-normal"> {lineItem.unit_price_type.name}</span>
                )}
              </span>
            }
          />
        </div>
      </RightRailSection>
      <RightRailSection>
        <RightRailItem
          label="Pacing Type"
          value={formatPacingType(lineItem.pacing_type)}
          cta={
            lineItem.pacing_type === 'custom' && (
              <ViewPacingSchedule openPacing={() => setExpanded('pacing')} />
            )
          }
        />
      </RightRailSection>
      <RightRailSection>
        <div className="flex flex-1 flex-col gap-0.5">
          <RightRailItem label="Targeting" value={lineItem.targeting} />
          <RightRailItem label="Audience" value={lineItem.audience} />
          <RightRailItem label="Geo" value={lineItem.geo} />
        </div>
      </RightRailSection>
      <RightRailSection>
        <div className="flex flex-1 flex-col gap-0.5">
          <RightRailItem label="Client goal" value={campaign?.Goals_KPIs} />
          <RightRailItem label="Client" value={campaign?.Account_Name.name} />{' '}
          <RightRailItem label="Agency" value={campaign?.Agency?.name} />
        </div>
      </RightRailSection>
    </div>
  );
}

import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { CombinedMediaBuy } from '../../store/strategy-combiner';
import {
  CellMouseOutEvent,
  CellMouseOverEvent,
  ColDef,
  ColGroupDef,
  ModuleRegistry,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy
} from '@ag-grid-community/core';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { AgGridReact } from '@ag-grid-community/react';
import { defaultColDef, formatCurrency } from '../../components/table-utils';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { MediaBuyUpdate } from 'shared/src/media-buy-types';
import { useStore } from '../../store/store';
import { findMetrics, hasMetrics } from '../line-items/line-items-table-utils';
import { MediaBuyMetrics } from '../../metrics/metrics-types';
import { MediaBuysStatusPanelLeft, MediaBuysStatusPanelRight } from './media-buys-status-panel';
import { getPlatformName } from 'shared/src/media-platforms';
import {
  DeliveredUnitsRenderer,
  MediaBuyLinkBudgetRenderer,
  PlatformBuyNameRenderer,
  MediaBuyTargetMarginRenderer,
  MediaSpendRenderer,
  MetricCellRenderer,
  PlatformRenderer,
  TargetUnitCostRenderer,
  TargetUnitsRenderer,
  ViewButtonCellRenderer,
  HeaderComponent
} from './media-buys-table-cell-components';
import { UnitPriceType } from '../../../../shared/src/line-item-channels';
import { formatPercentage, getUnitName } from '../../../../shared/src/line-item-utils';

ModuleRegistry.registerModules([RowGroupingModule]);

export type MediaBuysTableData = CombinedMediaBuy & {
  button: '';
  deliveryPacing: number;
  spendPacing: number;
  marginPerformance: number;
  margin: number;
  targetMargin: number;
  totalTargetUnits: number;
  deliveredUnits: number;
  mediaSpend: number;
  currentTargetUnits: number;
  currentPlannedSpend: number;
  revenue: number;
  totalTargetRevenue: number;
  totalTargetCost: number;
  priceType: UnitPriceType['name'];
  adjustedTargetUnitCost: number;
};

type Props = {
  mediaBuys: CombinedMediaBuy[];
  colorMap: Record<string, string>;
  metrics: MediaBuyMetrics[];
  unitPriceType: UnitPriceType | undefined;
  lineItemBudget: number | undefined;
};

const autoSizeStrategy:
  | SizeColumnsToFitGridStrategy
  | SizeColumnsToFitProvidedWidthStrategy
  | SizeColumnsToContentStrategy = {
  type: 'fitCellContents'
};

export function MediaBuysTable({
  mediaBuys,
  metrics,
  colorMap,
  unitPriceType,
  lineItemBudget
}: Props) {
  const setHighlightedGraph = useStore(state => state.setHighlightedGraph);
  const gridRef = useRef<AgGridReact<MediaBuysTableData>>(null);

  const onCellMouseOut = useCallback(
    (e: CellMouseOutEvent<MediaBuysTableData>) => {
      if (!e.event || !e.data) return;
      if (!e.node.isHovered()) setHighlightedGraph(null);
    },
    [setHighlightedGraph]
  );

  const onCellMouseOver = useCallback(
    (e: CellMouseOverEvent<MediaBuysTableData>) => {
      if (!e.event || !e.data) return;
      if (e.node.isHovered()) {
        setHighlightedGraph(e.data.media_buy.id);
      }
    },
    [setHighlightedGraph]
  );

  const mediaBuysTableData = useMemo(
    () =>
      mediaBuys.map(mediaBuy => ({
        ...mediaBuy,
        ...findMetrics(mediaBuy.media_buy.id, metrics)
      })),
    [mediaBuys, metrics]
  );

  useEffect(() => {
    setTimeout(() => gridRef.current?.api?.autoSizeAllColumns(), 250);
  }, [mediaBuysTableData]);

  const onMouseLeave = useCallback(() => {
    setTimeout(() => setHighlightedGraph(null), 500);
  }, [setHighlightedGraph]);

  return (
    <div className="flex h-full w-full flex-col">
      <div
        className="ag-theme-quartz"
        style={{
          height: mediaBuysTableData.length < 4 ? '100%' : 'calc(.33 * (100vh - 150px))', // let the table take up roughly a third of the available height
          width: '100%'
        }}
        onMouseLeave={onMouseLeave}>
        <AgGridReact
          gridOptions={{
            headerHeight: 36,
            rowHeight: 36
          }}
          rowData={mediaBuysTableData}
          ref={gridRef}
          columnDefs={getColumnDefs(
            colorMap,
            hasMetrics(metrics),
            unitPriceType?.name || 'CPM',
            lineItemBudget
          )}
          defaultColDef={defaultColDef}
          domLayout={mediaBuysTableData.length < 4 ? 'autoHeight' : 'normal'}
          onCellMouseOver={onCellMouseOver}
          onCellMouseOut={onCellMouseOut}
          autoSizeStrategy={autoSizeStrategy}
          statusBar={{
            statusPanels: [
              { statusPanel: MediaBuysStatusPanelLeft, align: 'left' },
              { statusPanel: MediaBuysStatusPanelRight, align: 'right' }
            ]
          }}
          grandTotalRow="bottom"
        />
      </div>
    </div>
  );
}

function getColumnDefs(
  colorMap: Record<string, string>,
  showMetrics: boolean,
  unitPriceTypeName: UnitPriceType['name'],
  lineItemBudget: number | undefined
): (ColDef<MediaBuysTableData> | ColGroupDef<CombinedMediaBuy>)[] {
  return [
    {
      field: 'button',
      cellRenderer: ViewButtonCellRenderer,
      cellRendererParams: { colorMap },
      headerName: '',
      pinned: 'left',
      minWidth: 120
    },
    {
      field: 'media_buy.media_platform_id',
      headerName: 'Platform',
      valueFormatter: params =>
        params.data ? getPlatformName(params.data.media_buy.media_platform_id) : '',
      cellStyle: ({ data }) => cellStyle(data, 'media_platform_id')
    },
    {
      field: 'link_name',
      headerName: 'Name',
      maxWidth: 700,
      initialWidth: 600,
      cellStyle: ({ data }) => cellStyle(data, 'link_name')
    },
    {
      field: 'media_buy.media_platform_id',
      headerName: 'Platform',
      cellRenderer: PlatformRenderer,
      cellStyle: ({ data }) => cellStyle(data, 'media_platform_id'),
      aggFunc: params => {
        const uniqueValues = new Set();
        params.values.forEach(value => uniqueValues.add(value));
        return uniqueValues.size;
      }
    },
    {
      field: 'link_budget',
      headerName: 'Plan Budget',
      cellDataType: 'number',
      // editable: true,
      cellRenderer: MediaBuyLinkBudgetRenderer,
      cellRendererParams: {
        lineItemBudget
      },
      cellStyle: () => numericalCellStyle,
      valueFormatter: params => {
        if (params.node?.group) {
          return formatCurrency(params.node.aggData.link_budget || 0);
        }
        return formatCurrency(params.data?.link_budget || 0);
      },
      aggFunc: 'sum'
    },
    {
      field: 'adjustedTargetUnitCost',
      headerName: `Plan ${unitPriceTypeName}`,
      cellDataType: 'number',
      // editable: true,
      cellRenderer: TargetUnitCostRenderer,
      cellStyle: () => numericalCellStyle,
      valueFormatter: params => {
        if (params.node?.group) {
          return formatCurrency(params.node.aggData.adjustedTargetUnitCost || 0);
        }
        return formatCurrency(params.data?.adjustedTargetUnitCost || 0);
      },
      aggFunc: params => {
        if (!params.rowNode?.aggData) return null;
        const { link_budget, totalTargetUnits } = params.rowNode.aggData;
        if (!totalTargetUnits || (!link_budget && link_budget !== 0)) return null;
        return unitPriceTypeName === 'CPM'
          ? (link_budget / totalTargetUnits) * 1000
          : link_budget / totalTargetUnits;
      }
    },
    {
      field: 'targetMargin',
      headerName: 'Plan Margin',
      cellDataType: 'number',
      cellRenderer: MediaBuyTargetMarginRenderer,
      cellStyle: () => numericalCellStyle,
      valueFormatter: params => {
        if (params.node?.group) {
          return formatPercentage(params.node.aggData.targetMargin || 0);
        }
        return formatPercentage(params.data?.targetMargin || 0);
      },
      aggFunc: params => {
        if (!params.rowNode?.aggData) return null;
        const { totalTargetRevenue, totalTargetCost } = params.rowNode.aggData;
        if (!totalTargetRevenue || (!totalTargetCost && totalTargetCost !== 0)) return null;
        return (totalTargetRevenue - totalTargetCost) / totalTargetRevenue;
      }
    },
    {
      field: 'totalTargetUnits',
      cellDataType: 'number',
      headerName: `Plan ${getUnitName(unitPriceTypeName).abbr}`,
      headerComponent: HeaderComponent,
      cellRenderer: TargetUnitsRenderer,
      cellStyle: () => numericalCellStyle,
      valueFormatter: params => {
        if (params.node?.group) {
          return Math.round(params.node.aggData.totalTargetUnits || 0).toLocaleString();
        }
        return Math.round(params.data?.totalTargetUnits || 0).toLocaleString();
      },
      aggFunc: 'sum'
    },
    {
      field: 'media_buy.name',
      headerName: 'Platform Buy Name',
      cellRenderer: PlatformBuyNameRenderer,
      maxWidth: 700
    },
    {
      field: 'deliveryPacing',
      headerName: 'Plan Delivery Pacing',
      hide: !showMetrics,
      cellRenderer: MetricCellRenderer,
      aggFunc: params => {
        if (!params.rowNode?.aggData) return null;
        const { currentTargetUnits, deliveredUnits } = params.rowNode.aggData;
        if ((!deliveredUnits && deliveredUnits !== 0) || !currentTargetUnits) return null;
        return deliveredUnits / currentTargetUnits;
      }
    },
    {
      field: 'spendPacing',
      headerName: 'Plan Spend Pacing',
      hide: !showMetrics,
      cellRenderer: MetricCellRenderer,
      aggFunc: params => {
        if (!params.rowNode?.aggData) return null;
        const { mediaSpend, currentPlannedSpend } = params.rowNode.aggData;
        if ((!mediaSpend && mediaSpend !== 0) || !currentPlannedSpend) return null;
        return mediaSpend / currentPlannedSpend;
      }
    },
    {
      field: 'margin',
      headerName: 'Plan Margin (actual)',
      hide: !showMetrics,
      cellRenderer: MetricCellRenderer,
      aggFunc: params => {
        if (!params.rowNode?.aggData) return null;
        const { revenue, mediaSpend } = params.rowNode.aggData;
        if (!revenue || (!mediaSpend && mediaSpend !== 0)) return null;
        return (revenue - mediaSpend) / revenue;
      }
    },
    {
      field: 'deliveredUnits',
      headerName: `${getUnitName(unitPriceTypeName).abbr} Delivered`,
      headerComponent: HeaderComponent,
      cellDataType: 'number',
      cellRenderer: DeliveredUnitsRenderer,
      cellStyle: () => numericalCellStyle,
      valueFormatter: params => {
        if (params.node?.group) {
          return Math.round(params.node?.aggData.deliveredUnits || 0).toLocaleString();
        }
        return Math.round(params.data?.deliveredUnits || 0).toLocaleString();
      },
      aggFunc: 'sum'
    },
    {
      field: 'mediaSpend',
      cellDataType: 'number',
      cellRenderer: MediaSpendRenderer,
      cellStyle: () => numericalCellStyle,
      aggFunc: 'sum'
    },
    {
      field: 'revenue',
      hide: true,
      aggFunc: 'sum'
    },
    {
      field: 'totalTargetRevenue',
      hide: true,
      aggFunc: 'sum'
    },
    {
      field: 'totalTargetCost',
      hide: true,
      valueGetter: params =>
        (params.data?.totalTargetUnits || 0) * (params.data?.target_unit_cost || 0),
      aggFunc: 'sum'
    },
    {
      field: 'currentTargetUnits',
      hide: true,
      aggFunc: 'sum'
    },
    {
      field: 'currentPlannedSpend',
      hide: true,
      aggFunc: 'sum'
    },
    {
      field: 'marginPerformance',
      hide: true,
      aggFunc: params => {
        if (!params.rowNode?.aggData) return null;
        const { margin, targetMargin } = params.rowNode.aggData;
        if (!targetMargin || (!margin && margin !== 0)) return null;
        return margin / targetMargin;
      }
    }
  ];
}

const numericalCellStyle = { textAlign: 'right' };

function cellStyle(data: CombinedMediaBuy | undefined, key: keyof MediaBuyUpdate) {
  return data?.state === 'new' || data?.dirty[key]
    ? { fontWeight: 'bold' }
    : { fontWeight: 'normal' };
}

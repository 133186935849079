import { z } from 'zod';
import { dateToUTC } from 'shared/src/zod-utils';

export type CreateCampaignOptions = {
  advertiser_id: number;
  campaign_name: string;
  campaign_budget: number;
  start_date: string;
  end_date: string;
  active: boolean;
  // 0=spend, 1=impressions, 2=spend with vendor fees
  budget_type: 0 | 1 | 2;
};

export type BiddingInput = {
  bidding_strategy: 'CPM';
  bid_shading?: boolean;
  bid_shading_win_rate_control?: 'NORMAL';
  pacing?: 'lifetime' | 'none';
  values: Record<string, string | number>;
};

export type BeeswaxPostBody = Record<string, string | boolean | number | object>;

export type CreateLineItemOptions = {
  campaign_id: number;
  advertiser_id: number;
  // 0=banner, 1=video, 2=native
  line_item_type_id: 0 | 1 | 2;
  line_item_name: string;
  line_item_budget: number;
  bidding: BiddingInput;
  start_date: string;
  end_date: string;
  active: boolean;
  // 0=spend, 1=impressions, 2=spend with vendor fees
  budget_type: 0 | 1 | 2;
};

export const BeeswaxCreateResponse = z.object({
  success: z.boolean(),
  payload: z.object({
    id: z.number()
  }),
  message: z.string()
});

export type CreateCampaignResponse = z.infer<typeof BeeswaxCreateResponse>;

export const BeeswaxErrorResponse = z.object({
  success: z.literal(false),
  errors: z.array(z.string())
});

export type BeeswaxErrorResponse = z.infer<typeof BeeswaxErrorResponse>;

export const BeeswaxGetResponse = z.object({
  success: z.literal(true),
  payload: z.array(z.object({}).catchall(z.any()))
});
export type BeeswaxGetResponse = z.infer<typeof BeeswaxGetResponse>;

const BiddingObject = z.object({
  values: z.object({
    cpm_bid: z.number().optional(),
    cpa_target: z.number().optional()
  }),
  bidding_strategy: z.string()
});

export const BeeswaxCampaignV1 = z.object({
  id: z.number(),
  advertiser_id: z.number(),
  campaign_name: z.string(),
  campaign_budget: z.number(),
  start_date: dateToUTC,
  end_date: dateToUTC.nullable(),
  active: z.boolean()
});

export type BeeswaxCampaignV1 = z.infer<typeof BeeswaxCampaignV1>;

export const BeeswaxLineItemV1 = z.object({
  advertiser_id: z.number(),
  bidding: BiddingObject,
  budget_type: z.number(),
  campaign_id: z.number(),
  line_item_budget: z.number(),
  line_item_id: z.number(),
  line_item_name: z.string(),
  line_item_spend: z.number().nullable(),
  line_item_type_id: z.number(),
  start_date: dateToUTC,
  end_date: dateToUTC.optional(),
  account_id: z.number(),
  create_date: z.string(),
  update_date: z.string(),
  active: z.boolean()
});
export type BeeswaxLineItemV1 = z.infer<typeof BeeswaxLineItemV1>;

export const BeeswaxLineItemResponse = z.object({
  success: z.literal(true),
  payload: z.array(BeeswaxLineItemV1)
});
export type BeeswaxLineItemResponse = z.infer<typeof BeeswaxLineItemResponse>;

export const WrappedBeeswaxLineItemV1 = z.object({
  schema: z.literal('brkthru.com/beeswax_line_item/1-0-0'),
  data: BeeswaxLineItemV1
});
export type WrappedBeeswaxLineItemV1 = z.infer<typeof WrappedBeeswaxLineItemV1>;

export const BeeswaxLineItem = z.union([BeeswaxLineItemV1, WrappedBeeswaxLineItemV1]);
export type BeeswaxLineItem = z.infer<typeof BeeswaxLineItem>;

export const WrappedBeeswaxCampaignV1 = z.object({
  schema: z.literal('brkthru.com/beeswax_campaign/1-0-0'),
  data: BeeswaxCampaignV1
});
export type WrappedBeeswaxCampaignV1 = z.infer<typeof WrappedBeeswaxCampaignV1>;

export const BeeswaxCampaign = z.union([WrappedBeeswaxCampaignV1, BeeswaxCampaignV1]);
export type BeeswaxCampaign = z.infer<typeof BeeswaxCampaign>;

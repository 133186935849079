import { MediaBuyMetrics } from '../../metrics/metrics-types';

export function hasMetrics(metrics: MediaBuyMetrics[]) {
  return metrics.some(m => m.deliveredUnits > 0);
}

export function findMetrics(id: string, metrics: MediaBuyMetrics[]) {
  const metric = metrics.find(m => m.id === id);
  return metric
    ? {
        deliveryPacing: metric.deliveryPacing,
        spendPacing: metric.spendPacing,
        marginPerformance: metric.marginPerformance,
        margin: metric.currentMargin,
        targetMargin: metric.targetMargin,
        totalTargetUnits: metric.totalTargetUnits,
        deliveredUnits: metric.deliveredUnits,
        mediaSpend: metric.mediaSpend,
        currentTargetUnits: metric.currentTargetUnits,
        currentPlannedSpend: metric.currentPlannedSpend,
        revenue: metric.revenue,
        totalTargetRevenue: metric.totalTargetRevenue,
        priceType: metric.priceType,
        adjustedTargetUnitCost: metric.adjustedTargetUnitCost
      }
    : {
        deliveryPacing: 0,
        spendPacing: 0,
        marginPerformance: 0,
        margin: 0,
        targetMargin: 0,
        totalTargetUnits: 0,
        deliveredUnits: 0,
        mediaSpend: 0,
        currentTargetUnits: 0,
        currentPlannedSpend: 0,
        revenue: 0,
        totalTargetRevenue: 0,
        priceType: 'CPM',
        adjustedTargetUnitCost: 0
      };
}

import { DraftStrategyChanges, Strategy } from 'shared/src/types';
import { mapValues } from 'lodash';
import {
  MediaBuy,
  MediaBuyChanges,
  MediaBuyLink,
  MediaPlatformEntity
} from 'shared/src/media-buy-types';
import { LineItem, NewLineItemDraft, PartialLineItem } from 'shared/src/line-item-types';

export type ChangeState = 'unchanged' | 'new' | 'updated';

export type CombinedMediaBuy = Omit<MediaBuyLink, 'media_buy'> & {
  media_buy: Omit<MediaBuy, 'media_platform_entity'> & {
    media_platform_entity?: MediaPlatformEntity;
  };
  state: ChangeState;
  dirty: { [K in keyof (Omit<MediaBuyLink, 'media_buy'> & MediaBuy)]?: true };
};

export type Dirty = {
  [K in keyof LineItem]?: { old: LineItem[K]; new: LineItem[K] | undefined } | null;
};

export type UpdatedLineItemState = {
  type: 'update';
  original: LineItem;
  dirty: Dirty;
};

export type NewLineItemState = {
  type: 'new';
};

export type UnchangedLineItemState = {
  type: 'unchanged';
};

export type LineItemChangeState = UpdatedLineItemState | NewLineItemState | UnchangedLineItemState;

export type CombinedLineItem = PartialLineItem & {
  state: LineItemChangeState;
  media_buys: Array<CombinedMediaBuy>;
};

export type CombinedStrategy = Omit<Strategy, 'line_items'> & {
  line_items: Array<CombinedLineItem>;
  changes: DraftStrategyChanges;
};

export function combineStrategy(
  strategy: Strategy,
  changes: DraftStrategyChanges
): CombinedStrategy {
  const updatedLineItems = strategy.line_items.map(lineItem =>
    combineLineItemUpdate(lineItem, changes)
  );
  const newLineItems = Object.values(changes.line_items).filter(item => item.type === 'new');

  const orderedLineItems = orderUpdatedItemsWithNewItems(
    updatedLineItems,
    newLineItems,
    changes.media_buys
  );

  return {
    ...strategy,
    line_items: orderedLineItems,
    changes
  };
}

export function orderUpdatedItemsWithNewItems(
  updatedItems: CombinedLineItem[],
  newLineItems: NewLineItemDraft[],
  mediaBuys: DraftStrategyChanges['media_buys']
) {
  let sortedItems = [...updatedItems];

  for (const changeItem of newLineItems) {
    if (changeItem.position) {
      if (changeItem.position === 'bottom') {
        // put item at end of list
        sortedItems.push(mapNewLineItem(changeItem, mediaBuys));
      } else {
        // look for item duplicated to position immediately beneath it
        const duplicatedItemIdx = sortedItems.findIndex(item => item.id === changeItem.position);

        if (duplicatedItemIdx > -1) {
          // item was singularly duplicated, so position immediately beneath duplicated item
          sortedItems = sortedItems.toSpliced(
            duplicatedItemIdx + 1,
            0,
            mapNewLineItem(changeItem, mediaBuys)
          );
        } else {
          // duplicate index was not found, so put at bottom of list
          // i don't think this case is possible, but just in case
          sortedItems.push(mapNewLineItem(changeItem, mediaBuys));
        }
      }
    } else {
      // newly created item, so put at end of list
      sortedItems.push(mapNewLineItem(changeItem, mediaBuys));
    }
  }

  return sortedItems;
}

function combineLineItemUpdate(
  lineItem: LineItem,
  changes: DraftStrategyChanges
): CombinedLineItem {
  const update = changes.line_items[lineItem.id];
  if (update && update.type === 'new')
    throw new Error('A server line item cannot also be created locally');
  return {
    ...lineItem,
    ...(update ? update.data : {}),
    state: update
      ? { type: 'update', original: lineItem, dirty: calcDirtyLineItems(lineItem, update.data) }
      : { type: 'unchanged' },
    media_buys: [
      ...lineItem.media_buys.map(mediaBuy => combineMediaBuy(mediaBuy, changes)),
      ...mapNewMediaBuys(lineItem.id, changes.media_buys)
    ]
  };
}

function calcDirtyLineItems(lineItem: LineItem, update: PartialLineItem): Dirty {
  // tactic and unit_price_type can be reset to undefined

  return {
    ...('name' in update ? { name: { old: lineItem.name, new: update.name } } : {}),
    ...('channel' in update ? { channel: { old: lineItem.channel, new: update.channel } } : {}),
    ...('tactic' in update ? { tactic: { old: lineItem.tactic, new: update.tactic } } : {}),
    ...('unit_price_type' in update
      ? { unit_price_type: { old: lineItem.unit_price_type, new: update.unit_price_type } }
      : {}),
    ...('geo' in update ? { geo: { old: lineItem.geo, new: update.geo } } : {}),
    ...('targeting' in update
      ? { targeting: { old: lineItem.targeting, new: update.targeting } }
      : {}),
    ...('ad_formats' in update
      ? { ad_formats: { old: lineItem.ad_formats, new: update.ad_formats } }
      : {}),
    ...('audience' in update ? { audience: { old: lineItem.audience, new: update.audience } } : {}),
    ...('price' in update ? { budget: { old: lineItem.price, new: update.price } } : {}),
    ...('target_margin' in update
      ? { target_margin: { old: lineItem.target_margin, new: update.target_margin } }
      : {}),
    ...('unit_price' in update
      ? { unit_price: { old: lineItem.unit_price, new: update.unit_price } }
      : {}),
    ...('start_date' in update
      ? { start_date: { old: lineItem.start_date, new: update.start_date } }
      : {}),
    ...('end_date' in update ? { end_date: { old: lineItem.end_date, new: update.end_date } } : {}),
    ...('pacing_type' in update
      ? { pacing_type: { old: lineItem.pacing_type, new: update.pacing_type } }
      : {}),
    ...('pacing_details' in update
      ? { pacing_details: { old: lineItem.pacing_details, new: update.pacing_details } }
      : {}),
    ...('media_traders' in update
      ? { media_traders: { old: lineItem.media_traders, new: update.media_traders } }
      : {}),
    ...('media_platforms' in update
      ? { media_platforms: { old: lineItem.media_platforms, new: update.media_platforms } }
      : {}),
    ...('is_deleted' in update
      ? { is_deleted: { old: lineItem.is_deleted, new: update.is_deleted } }
      : {})
  };
}

function mapNewLineItem(
  newLineItem: NewLineItemDraft,
  mediaBuys: DraftStrategyChanges['media_buys']
): CombinedLineItem {
  return {
    ...newLineItem.data,
    state: { type: 'new' },
    media_buys: mapNewMediaBuys(newLineItem.data.id, mediaBuys)
  };
}

function mapNewMediaBuys(
  lineItemId: string,
  mediaBuys: Record<string, MediaBuyChanges>
): CombinedMediaBuy[] {
  return Object.values(mediaBuys)
    .filter(change => change.type === 'new')
    .filter(change => change.data.line_item_id === lineItemId)
    .map(newMediaBuy => ({
      media_buy: {
        ...newMediaBuy.data
      },
      link_budget: 0,
      link_name: '',
      target_unit_cost: 0,
      state: 'new' as const,
      dirty: {}
    }));
}

function combineMediaBuy(
  mediaBuyLink: MediaBuyLink,
  changes: DraftStrategyChanges
): CombinedMediaBuy {
  const update = changes.media_buys[mediaBuyLink.media_buy.id];

  if (update && update.type === 'new')
    throw new Error('A media buy cannot also be created locally');

  return {
    ...mediaBuyLink,
    ...(update ? update.data : {}),
    state: update ? 'updated' : 'unchanged',
    dirty: update ? mapValues({ ...update.data }, () => true as const) : {}
  };
}

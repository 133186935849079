import { CombinedLineItem, CombinedStrategy } from '../../store/strategy-combiner';
import { DialogTitle } from '@headlessui/react';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useStore } from '../../store/store';
import { trpc } from '../../utils/trpc-client';
import {
  changesAmount,
  changesCount,
  convertStrategyChanges,
  getChanges
} from './pending-changes-utils';
import { formatMoney } from 'shared/src/money-utils';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import { NewLineItem, NewMediaBuy } from '../line-items/new-line-item';
import { hasLineItemErrors, LineItemErrors } from '../line-items/line-item-errors';
import { LineItemDiffs } from './pending-changes-components';
import { faArrowDown } from '@fortawesome/pro-light-svg-icons';
import { IntercomIcon } from '../../components/intercom-icon';
import { useIntercom } from 'react-use-intercom';

type FormData = {
  comment: string;
};

type Props = {
  strategy: CombinedStrategy;
  refetch: () => Promise<unknown>;
  setOpen: (open: boolean) => unknown;
};

export function Hr() {
  return (
    <div className="px-6">
      <hr className="h-[2px] rounded border-0 bg-gray-200" />
    </div>
  );
}

export function CloseButton(props: { onClick: () => void }) {
  return (
    <div className="ml-3 flex h-7 items-center">
      <button
        type="button"
        onClick={props.onClick}
        className="relative -m-2 p-2 text-gray-400 hover:text-gray-500">
        <span className="absolute -inset-0.5" />
        <span className="sr-only">Close panel</span>
        <FontAwesomeIcon icon={faClose} className="h-6 w-6" />
      </button>
    </div>
  );
}

export function PendingChanges({ strategy, refetch, setOpen }: Props) {
  const resetChanges = useStore(state => state.resetChanges);
  const { mutateAsync, isPending, isError } = trpc.updateStrategy.useMutation({
    onSuccess: async () => {
      resetChanges(strategy.id);
      await refetch();
      setOpen(false);
    }
  });
  const { register, handleSubmit, formState } = useForm<FormData>();
  const { errors } = formState;
  const { lineItems, mediaBuys } = getChanges(strategy);
  const count = changesCount(strategy);
  const budgetChange = changesAmount(strategy);

  const invalidLineItems = [...lineItems.created, ...lineItems.updated].some(hasLineItemErrors);

  async function publishChanges(data: FormData) {
    await mutateAsync({
      strategy_id: strategy.id,
      description: data.comment,
      changes: convertStrategyChanges(strategy.changes)
    });
  }

  return (
    <div className="flex h-full w-[650px] flex-col bg-white shadow-xl">
      <div className="px-6 py-6">
        <div className="flex items-start justify-between">
          <DialogTitle className="text-lg font-medium text-gray-900">
            {count} Pending Change{count === 1 ? '' : 's'}
          </DialogTitle>
          <CloseButton onClick={() => setOpen(false)} />
        </div>
      </div>
      <Hr />
      <div className="mt-4 flex-1 overflow-y-auto">
        {lineItems.created.map(lineItem => (
          <NewLineItem key={lineItem.id} lineItem={lineItem} />
        ))}
        {lineItems.updated.map(lineItem => (
          <PendingLineItem key={lineItem.id} lineItem={lineItem} />
        ))}
        {mediaBuys.created.map(mediaBuy => (
          <NewMediaBuy key={mediaBuy.media_buy.id} mediaBuy={mediaBuy} />
        ))}
      </div>
      {invalidLineItems && (
        <div className="flex items-center justify-between px-6 pb-2">
          <div className="mt-2 flex w-full flex-col rounded-md bg-red-100 p-2 px-2 font-light">
            <div className="text-sm font-bold">Unable to Save</div>
            <div className="text-sm font-light">
              Please fix the errors above in order to publish
            </div>
          </div>
        </div>
      )}
      <div className="flex items-center justify-between px-6 pb-2">
        <div className="text-sm text-gray-500">Budget Change</div>
        {budgetChange === 0 && <div>No Change</div>}
        {budgetChange > 0 && <div className="text-green-700">+{formatMoney(budgetChange)}</div>}
        {budgetChange < 0 && <div className="text-red-500">{formatMoney(budgetChange)}</div>}
      </div>
      <Hr />
      <div className="px-6 py-6 ">
        <form onSubmit={handleSubmit(publishChanges)}>
          <div className="mb-4 flex flex-col">
            <div className="flex items-center justify-between">
              <div className="mb-2 text-sm text-gray-500">Comment</div>
              {errors.comment && <div className="text-sm text-red-500">required</div>}
            </div>
            <textarea
              {...register('comment', { required: true })}
              placeholder="Comment on your changes"
              className={`w-full resize-none rounded border-2 ${errors.comment ? 'border-red-400' : 'border-gray-300'} font-light`}
              rows={2}
            />
          </div>
          {isError && <PublishError />}
          <div className="mt-4 w-full">
            <button
              disabled={isPending || invalidLineItems}
              type="submit"
              className="inline-flex h-12 w-full items-center justify-center rounded-md border border-transparent bg-sky-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 disabled:bg-sky-300">
              <div className="mr-2 text-lg">{isPending ? 'Publishing' : 'Publish'}</div>
              {isPending && (
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{
                    ease: 'linear',
                    duration: 2,
                    repeat: Infinity
                  }}>
                  <FontAwesomeIcon icon={faSpinner} />
                </motion.div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

function PendingLineItem({ lineItem }: { lineItem: CombinedLineItem }) {
  const removeLineItem = useStore(state => state.removeLineItem);
  if (lineItem.state.type !== 'update') return null;
  const numUpdates = Object.keys(lineItem.state.dirty).length;
  return (
    <div key={lineItem.id} className="mb-10 px-6">
      <div className="mb-4 flex items-center">
        <div className="mr-4 font-bold">{lineItem.name}</div>
        <div className="rounded-lg bg-gray-100 px-2 py-[3px] text-xs font-light text-gray-500">
          {numUpdates} Update{numUpdates > 1 ? 's' : ''}
        </div>
        <div className="flex-1" />
        <div
          className="text-sm font-light text-red-500 hover:cursor-pointer hover:underline"
          onClick={() => removeLineItem(lineItem.strategy_id, lineItem.id)}>
          Discard
        </div>
      </div>
      <LineItemDiffs lineItem={lineItem} />
      <LineItemErrors lineItem={lineItem} />
    </div>
  );
}

function PublishError() {
  const { showNewMessage: showNewIntercomMessage } = useIntercom();

  return (
    <div className="flex gap-4 rounded bg-red-600 p-2 pl-4">
      <div className="flex flex-col gap-1 text-white">
        <div className="text-lg font-semibold">Publish failed.</div>
        <div className="text-sm">
          <div>Your work isn’t lost. It’s stored on your computer until it’s Published.</div>
          <div className="font-semibold">You can try again, or...</div>
        </div>
      </div>
      <div className="flex basis-[300px] gap-3 rounded bg-white py-2 pl-4 pr-2">
        <div className="flex items-center text-sm text-gray-700">
          If the issue continues, send a message using Intercom at the bottom right of your screen.
        </div>
        <div
          onClick={() => showNewIntercomMessage()}
          className="flex cursor-pointer flex-col items-center justify-between rounded rounded-t-3xl bg-[#0171B2] p-2 pt-3">
          <IntercomIcon />
          <FontAwesomeIcon color="white" icon={faArrowDown} className="h-6 w-6" />
        </div>
      </div>
    </div>
  );
}

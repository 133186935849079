import React from 'react';
import { toast } from 'react-toastify';

export function showDuplicatedItemsToast(numItems: number) {
  toast(<DuplicatedItemsToast numItems={numItems} />, {
    autoClose: 5000,
    closeOnClick: true
  });
}

type Props = { numItems: number };

function DuplicatedItemsToast({ numItems }: Props) {
  return (
    <div className="flex items-center rounded bg-gray-950 px-4 py-2 text-white">
      {numItems} line items duplicated and added to the bottom of the table.
    </div>
  );
}

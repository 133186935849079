import { z } from 'zod';
import history from 'history/browser';
import { LineItemListUrlParams } from 'shared/src/line-item-types';

export function storeAllLineItemUrlParams(params: LineItemListUrlParams) {
  if (!params.sort && Object.keys(params.filters).length === 0) return;
  storeUrlObject('params', params);
}

export function getAllLineItemUrlParams(): LineItemListUrlParams | undefined {
  return getUrlObject(LineItemListUrlParams, 'params');
}

function storeUrlObject(paramName: string, value: any) {
  let urlParams = new URLSearchParams({ [paramName]: encodeURIComponent(JSON.stringify(value)) });
  history.push({ search: urlParams.toString() });
}

export function getUrlObject<T extends z.ZodTypeAny>(
  schema: T,
  paramName: string
): z.infer<T> | undefined {
  const urlParams = new URLSearchParams(history.location.search);
  const value = urlParams.get(paramName);
  if (!value) return undefined;

  try {
    const result = schema.safeParse(JSON.parse(decodeURIComponent(value)));
    if (result.success) {
      return result.data;
    }
  } catch (e) {
    console.error(`Failed to parse ${paramName} from URL params`, e);
    updateQueryStringParam(paramName, ''); // Reset the URL param if it's invalid
  }
  return undefined;
}

function updateQueryStringParam(paramName: string, value: string) {
  let currentUrlParams = new URLSearchParams(history.location.search);
  currentUrlParams.set(paramName, value);
  history.push({ search: currentUrlParams.toString() });
}

import { z } from 'zod';

export const ZohoAccount = z.object({
  id: z.string(),
  Account_Name: z.string(),
  Team_Name: z.object({
    id: z.string(),
    name: z.string()
  })
});
export type ZohoAccount = z.infer<typeof ZohoAccount>;

export const ZohoTeam = z.object({
  id: z.string(),
  Team_Assignments: z.array(
    z.object({
      Team_Assignments: z.object({
        name: z.string(),
        id: z.string()
      }),
      id: z.string()
    })
  )
});
export type ZohoTeam = z.infer<typeof ZohoTeam>;

export const ZohoTeamAssignment = z.object({
  id: z.string(),
  name: z.string()
});
export type ZohoTeamAssignment = z.infer<typeof ZohoTeam>;

export const ZohoUser = z.object({
  id: z.string(),
  full_name: z.string(),
  email: z.string()
});
export type ZohoUser = z.infer<typeof ZohoUser>;

export const EntityZohoUser = z.object({
  id: z.string(),
  name: z.string()
});
export type EntityZohoUser = z.infer<typeof EntityZohoUser>;

export const ZohoCampaign = z.object({
  id: z.string(),
  Deal_Number: z.string().optional(),
  Deal_Name: z.string(),
  Account_Name: z.object({
    id: z.string(),
    name: z.string()
  }),
  Agency: z.union([
    z.object({
      id: z.string(),
      name: z.string()
    }),
    z.null()
  ]),
  Budget: z.union([z.number(), z.null()]),
  New_Business: z.boolean().optional(),
  Stage: z.string(),
  Owner: z.object({
    id: z.string(),
    name: z.string()
  }),
  Created_Time: z.string(),
  Flight_Date: z.union([z.string(), z.null()]),
  End_Date: z.union([z.string(), z.null()]),
  Goals_KPIs: z.union([z.string(), z.null()]),
  Lead_Account_Owner: z.union([EntityZohoUser, z.null()]),
  Sales_Rep: z.union([EntityZohoUser, z.null()])
});
export type ZohoCampaign = z.infer<typeof ZohoCampaign>;

export const ZohoCampaignName = z.object({
  id: z.string(),
  Deal_Name: z.string(),
  Deal_Number: z.string()
});
export type ZohoCampaignName = z.infer<typeof ZohoCampaignName>;

export const ZohoCampaignId = z.object({
  zoho_campaign_id: z.string()
});
export type ZohoCampaignId = z.infer<typeof ZohoCampaignId>;

export const StrategyId = z.object({
  strategy_id: z.string()
});
export type StrategyId = z.infer<typeof StrategyId>;

export const UserIds = z.array(z.string());
export type UserIds = z.infer<typeof UserIds>;

export const UserSearchResponse = z.object({
  users: z.array(ZohoUser)
});
export type UserSearchResponse = z.infer<typeof UserSearchResponse>;

export const SearchResponseInfo = z.object({
  per_page: z.number(),
  count: z.number(),
  sort_by: z.string(),
  page: z.number(),
  sort_order: z.string(),
  more_records: z.boolean()
});

export const ZohoRep = z.object({
  Owner: z.object({
    id: z.string(),
    name: z.string(),
    email: z.string()
  }),
  id: z.string(),
  Rep_Type: z.string().nullable(),
  Name: z.string()
});

export const ZohoRepResponse = z.object({
  data: z.array(ZohoRep),
  info: SearchResponseInfo
});
export type ZohoRepResponse = z.infer<typeof ZohoRepResponse>;

export const ZohoAccountResponse = z.object({
  data: z.array(ZohoAccount)
});
export type ZohoAccountResponse = z.infer<typeof ZohoAccountResponse>;

export const ZohoTeamResponse = z.object({
  data: z.array(ZohoTeam)
});
export type ZohoTeamResponse = z.infer<typeof ZohoTeamResponse>;

export const ZohoTeamAssignmentsResponse = z.object({
  data: z.array(
    z.object({
      id: z.string(),
      Team_Assignments: z.object({
        Owner: z.object({ id: z.string(), name: z.string(), email: z.string() }),
        Rep_Type: z.string().nullable()
      })
    })
  )
});
export type ZohoTeamAssignmentsResponse = z.infer<typeof ZohoTeamAssignmentsResponse>;

export const ZohoCampaignResponse = z.object({
  data: z.array(ZohoCampaign)
});
export type CampaignResponse = z.infer<typeof ZohoCampaignResponse>;

export const ZohoCampaignNameResponse = z.object({
  data: z.array(ZohoCampaignName)
});
export type CampaignNameResponse = z.infer<typeof ZohoCampaignNameResponse>;

export const ZohoCampaignSearchResponseNoCount = ZohoCampaignResponse.extend({
  data: z.array(ZohoCampaign),
  info: SearchResponseInfo.optional()
});
export type ZohoCampaignSearchResponseNoCount = z.infer<typeof ZohoCampaignSearchResponseNoCount>;

export const ZohoCampaignSearchResponse = ZohoCampaignSearchResponseNoCount.extend({
  total_count: z.number()
});
export type ZohoCampaignSearchResponse = z.infer<typeof ZohoCampaignSearchResponse>;

export const CountResponse = z.object({
  count: z.number()
});
export type CountResponse = z.infer<typeof CountResponse>;

export const ZohoCampaignStatus = z.union([
  z.literal('Potential Opportunity'),
  z.literal('New'),
  z.literal('Media Campaign Details'),
  z.literal('Avails/Plan Development'),
  z.literal('Proposal Sent'),
  z.literal('Book the Campaign'),
  z.literal('Traffic & Onboard'),
  z.literal('Pixel Setup'),
  z.literal('Campaign is Live'),
  z.literal('Campaign is Paused'),
  z.literal('Campaign Management & Reporting'),
  z.literal('Campaign Complete'),
  z.literal('Renew Campaign'),
  z.literal('Closed (Lost)'),
  z.literal('Exit - Dev Only'),
  z.literal('Campaign Approved to Launch'),
  z.literal('Campaign Cancelled'),
  z.literal('Pixel Check'),
  z.literal('Potential')
]);
export type ZohoCampaignStatus = z.infer<typeof ZohoCampaignStatus>;
export const zohoCampaignStatusValues = ZohoCampaignStatus.options.map(option => option.value);

export const NumberFilter = z.object({
  filter: z.number(),
  type: z.union([z.literal('equals'), z.literal('greaterThan'), z.literal('lessThan')]),
  filterType: z.literal('number')
});

export const LogicalNumberFilter = z.object({
  operator: z.union([z.literal('AND'), z.literal('OR')]),
  conditions: z.array(NumberFilter),
  filterType: z.literal('number')
});

export const ZohoSearchRequest = z.object({
  page: z.number(),
  search: z.string().optional(),
  status: z.array(ZohoCampaignStatus).optional(),
  amName: z.string().optional(),
  csdName: z.string().optional(),
  price: z.union([NumberFilter, LogicalNumberFilter]).optional()
});
export type ZohoSearchRequest = z.infer<typeof ZohoSearchRequest>;

import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { PendingChangesButton } from '../pending-changes/pending-changes-button';
import { MediaBuyOverview } from './media-buy-overview-page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobePointer } from '@fortawesome/pro-light-svg-icons';
import { useStrategyContext } from '../line-items/strategy-context';
import { RefreshMediaBuyButton } from './refresh-media-buy-button';
import { NavItem } from '../../components/navitem';
import { TopSection } from '../../components/layout';
import { CombinedMediaBuy } from '../../store/strategy-combiner';
import { generateDeeplink } from './media-buy-utils';
import { MediaBuyBreadcrumbs } from '../shared/breadcrumbs';

export function MediaBuyPage() {
  const { strategy, campaign, refetch } = useStrategyContext();
  const { mediaBuyId, lineItemId } = useParams();

  const lineItem = strategy.line_items.find(li => li.id === lineItemId);
  const mediaBuy = lineItem?.media_buys.find(mb => mb.media_buy.id === mediaBuyId);

  if (!lineItem || !mediaBuy) {
    console.warn(`Line item with id ${lineItemId} not found`);
    return null;
  }

  return (
    <div className="flex w-full flex-col">
      <TopSection>
        <MediaBuyBreadcrumbs campaign={campaign} lineItem={lineItem} mediaBuy={mediaBuy} />
        <div className="flex-1" />
        <div className="flex items-end justify-between">
          <div className="flex items-end">
            <NavItem to="overview" label="Overview" />
            <NavItem to="settings" label="Settings" />
          </div>
          <div className="ml-auto flex space-x-4 pb-2">
            <RefreshMediaBuyButton mediaBuyId={mediaBuy.media_buy.id} refetch={refetch} />
            <DeepLink mediaBuy={mediaBuy} />
          </div>
        </div>
      </TopSection>
      <PendingChangesButton />
      <Routes>
        <Route
          path="overview"
          element={<MediaBuyOverview campaign={campaign} mediaBuy={mediaBuy} lineItem={lineItem} />}
        />
        <Route
          path="settings"
          element={<MediaBuyOverview campaign={campaign} mediaBuy={mediaBuy} lineItem={lineItem} />}
        />
      </Routes>
    </div>
  );
}

function DeepLink({ mediaBuy }: { mediaBuy: CombinedMediaBuy }) {
  if (hasMediaEntity(mediaBuy)) return null;

  return (
    <a
      target="_blank"
      href={generateDeeplink(mediaBuy)}
      className="text-xs font-light text-blue-600"
      rel="noreferrer">
      <FontAwesomeIcon className="mr-2" icon={faGlobePointer} />
      View on Platform
    </a>
  );
}

function hasMediaEntity(mediaBuy: CombinedMediaBuy) {
  const { media_platform_entity } = mediaBuy.media_buy;
  return (
    !media_platform_entity?.raw_entity ||
    Object.keys(media_platform_entity?.raw_entity).length === 0
  );
}

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact, CustomCellRendererProps } from '@ag-grid-community/react';
import {
  CellKeyDownEvent,
  CellStyle,
  ColDef,
  GetRowIdParams,
  IRichCellEditorParams,
  KeyCreatorParams,
  ModuleRegistry,
  NewValueParams,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
  ValueFormatterParams
} from '@ag-grid-community/core';
import { useStore } from '../../store/store';
import { CombinedLineItem } from '../../store/strategy-combiner';
import { defaultColDef, formatCurrency } from '../../components/table-utils';
import { getCumulativeSpend, isLineItemValid } from './pacing-helpers';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { SparklinesModule } from '@ag-grid-enterprise/sparklines';
import {
  updateAdFormats,
  updateAudience,
  updateChannel,
  updateEndDate,
  updateGeo,
  updateMediaTraders,
  updateName,
  updatePacingType,
  updatePlatforms,
  updatePrice,
  updateStartDate,
  updateTactic,
  updateTargeting,
  updateTargetMargin,
  updateUnitPrice,
  updateUnitPriceType
} from './line-items-update-helper';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { PartialLineItem } from 'shared/src/line-item-types';
import { User } from 'shared/src/user-types';
import {
  allTactics,
  allUnitPriceTypes,
  Channel,
  ChannelSelection,
  Platform,
  platformsForTactics,
  TacticSelection,
  tacticsForChannel,
  UnitPriceType,
  unitPriceTypesForChannel
} from 'shared/src/line-item-channels';
import { PlatformCellRenderer } from './platform-cell-renderer';
import { useChannelRatesContext } from '../strategy/channel-rates-context';
import {
  CellRendererProps,
  ChannelCellRenderer,
  EmptyPacingDetails,
  EstimatedUnitsRenderer,
  getValueString,
  MediaBudgetRenderer,
  MetricCellRenderer,
  NullPacingDetails,
  TargetMarginCellEditor,
  TargetMarginCellRenderer,
  TargetMarginTooltip,
  UnitCostsRenderer,
  UnitPriceCellRenderer,
  UnitPriceEditor,
  UnitPriceTooltip
} from './line-item-table-cell-components';
import { LineItemStatusPanelLeft, LineItemStatusPanelRight } from './line-items-status-panel';
import {
  calcAdjustedUnitCost,
  calcEstimatedUnits,
  calcMediaBudget
} from 'shared/src/line-item-utils';
import { LineItemActions } from './line-item-actions';
import { formatDate } from 'shared/src/date-utils';
import { MediaBuyMetrics } from '../../metrics/metrics-types';
import { findMetrics, hasMetrics } from './line-items-table-utils';
import { DisallowZeroPriceModal } from './disallow-zero-price-modal';
import { UTCDate } from '@date-fns/utc';

ModuleRegistry.registerModules([ClientSideRowModelModule, SparklinesModule, RichSelectModule]);

export const autoSizeStrategy:
  | SizeColumnsToFitGridStrategy
  | SizeColumnsToFitProvidedWidthStrategy
  | SizeColumnsToContentStrategy = {
  type: 'fitCellContents'
};

export type LineItemTableData = CombinedLineItem & {
  deliveryPacing: number;
  spendPacing: number;
  marginPerformance: number;
  margin: number;
  pacing_graph: number[];
  estimated_units: number | undefined;
  unit_cost: number | undefined;
  media_budget: number | undefined;
  button: string;
  actions: string;
};

type Props = {
  lineItems: CombinedLineItem[];
  mediaTraders: User[] | undefined;
  setSelectedRows: (rows: LineItemTableData[]) => void;
  handleKeyboardDuplicate: (currentRow: LineItemTableData) => void;
  hideRowActions: boolean;
  metrics: MediaBuyMetrics[];
};

export function LineItemsTable({
  lineItems,
  mediaTraders,
  setSelectedRows,
  handleKeyboardDuplicate,
  hideRowActions,
  metrics
}: Props) {
  const [showPriceModal, setShowPriceModal] = useState<boolean>(false);
  const gridRef = useRef<AgGridReact<LineItemTableData>>(null);
  const { channels } = useChannelRatesContext();
  const columnDefs: ColDef<LineItemTableData>[] = useMemo(
    () => createColumnDefs(channels, mediaTraders, hasMetrics(metrics)),
    [channels, mediaTraders, metrics]
  );

  useEffect(() => {
    // ag-grid sometimes does not properly refresh cells after the data
    // has changed, resulting in styling not being applied correctly.
    // this effect forces a refresh when lineItems changes, resolving the issue
    gridRef.current?.api?.refreshCells({ force: true });
  }, [lineItems]);

  useEffect(() => {
    if (hideRowActions) gridRef.current?.api?.setColumnsVisible(['actions'], false);
    else gridRef.current?.api?.setColumnsVisible(['actions'], true);
  }, [hideRowActions]);

  const getRowId = useCallback((params: GetRowIdParams<LineItemTableData>) => params.data.id, []);

  const onSelectionChanged = useCallback(() => {
    const updatedRows: LineItemTableData[] = gridRef.current!.api.getSelectedRows();
    setSelectedRows(updatedRows);
  }, [setSelectedRows]);

  const onCellKeyDown = useCallback(
    (e: CellKeyDownEvent<LineItemTableData>) => {
      if (!e.event) return;
      const keyboardEvent = e.event as unknown as KeyboardEvent;
      const { code, metaKey, ctrlKey } = keyboardEvent;
      const { data } = e.node;
      if (code === 'KeyD' && (metaKey || ctrlKey) && data) {
        handleKeyboardDuplicate(data);
      }
    },
    [handleKeyboardDuplicate]
  );

  const rows = useMemo(() => {
    return lineItems
      .filter(lineItem => !lineItem.is_deleted)
      .map(lineItem => ({
        ...lineItem,
        ...findMetrics(lineItem.id, metrics),
        pacing_graph: generateGraph(lineItem),
        estimated_units: calcEstimatedUnits(lineItem),
        unit_cost: calcAdjustedUnitCost(lineItem),
        media_budget: calcMediaBudget(lineItem),
        button: '',
        actions: ''
      }));
  }, [lineItems, metrics]);

  useEffect(() => {
    setTimeout(() => gridRef.current?.api?.autoSizeAllColumns(), 250);
  }, [rows]);

  return (
    <div className="flex h-full w-full flex-col">
      <div className="ag-theme-quartz" style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={rows}
          columnDefs={columnDefs}
          onCellEditingStopped={e => {
            if (
              e.colDef.field &&
              ['price', 'unit_price'].includes(e.colDef.field) &&
              isZeroValue(e.newValue)
            ) {
              setShowPriceModal(true);
            }
          }}
          gridOptions={{
            headerHeight: 36,
            rowHeight: 36
          }}
          stopEditingWhenCellsLoseFocus={true}
          defaultColDef={defaultColDef}
          isRowSelectable={() => true}
          domLayout={'normal'}
          rowSelection="multiple"
          suppressRowClickSelection
          onSelectionChanged={onSelectionChanged}
          getRowId={getRowId}
          tooltipShowDelay={0}
          onCellKeyDown={onCellKeyDown}
          autoSizeStrategy={autoSizeStrategy}
          statusBar={{
            statusPanels: [
              { statusPanel: LineItemStatusPanelLeft, align: 'left' },
              { statusPanel: LineItemStatusPanelRight, align: 'right' }
            ]
          }}
        />
      </div>
      <DisallowZeroPriceModal open={showPriceModal} setOpen={setShowPriceModal} />
    </div>
  );
}

function createColumnDefs(
  channels: Channel[],
  mediaTraders: User[] | undefined,
  showMetrics: boolean
): ColDef<LineItemTableData>[] {
  const tactics = allTactics(channels);
  const unitPriceTypes = allUnitPriceTypes(channels);

  return [
    {
      field: 'button',
      headerName: '',
      pinned: 'left',
      resizable: false,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressHeaderFilterButton: true,
      suppressHeaderContextMenu: true,
      suppressMovable: true
    },
    {
      field: 'name',
      cellStyle: ({ data }) => cellStyle(data, 'name'),
      editable: true,
      maxWidth: 500,
      onCellValueChanged: updateName,
      cellRenderer: cellRendererCmp('name', 'Name this line item')
    },
    {
      field: 'deliveryPacing',
      hide: !showMetrics,
      cellRenderer: MetricCellRenderer,
      initialWidth: 150
    },
    {
      field: 'spendPacing',
      hide: !showMetrics,
      cellRenderer: MetricCellRenderer,
      initialWidth: 150
    },
    {
      field: 'margin',
      hide: !showMetrics,
      cellRenderer: MetricCellRenderer,
      initialWidth: 150
    },
    {
      field: 'price',
      headerName: 'Price',
      cellDataType: 'number',
      editable: true,
      valueFormatter: params => formatCurrency(params.data?.price || 0),
      valueSetter: params => {
        if (isZeroValue(params.newValue)) {
          return false;
        }
        params.data.price = params.newValue;
        return true;
      },
      cellStyle: ({ data }) => cellStyle(data, 'price'),
      onCellValueChanged: updatePrice,
      filter: 'agNumberColumnFilter',
      filterParams: {
        allowedCharPattern: '\\d\\-\\,'
      }
    },
    {
      field: 'channel',
      cellStyle: ({ data }) => cellStyle(data, 'channel'),
      keyCreator: params => params.value.name,
      valueParser: params => channels.find(c => c.name === params.newValue),
      comparator: (a, b) => a.name.localeCompare(b.name),
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: channels,
        formatValue: value => value && value.name
      } as IRichCellEditorParams<LineItemTableData, ChannelSelection>,
      cellRenderer: ChannelCellRenderer,
      valueFormatter: params => params.value?.name,
      onCellValueChanged: updateChannel,
      singleClickEdit: true,
      editable: true,
      filter: 'agSetColumnFilter',
      filterParams: { values: channels }
    },
    {
      field: 'tactic',
      cellStyle: ({ data }) => cellStyle(data, 'tactic'),
      keyCreator: params => params.value.id,
      comparator: (a, b) => a.name.localeCompare(b.name),
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: (params: any) =>
        ({
          values: params.data?.channel ? tacticsForChannel(channels, params.data.channel) : [],
          formatValue: value => value && value.name
        }) as IRichCellEditorParams<LineItemTableData, TacticSelection>,
      onCellValueChanged: updateTactic,
      cellRenderer: cellRendererCmp('tactic', 'Select the Tactic'),
      editable: ({ data }) => data?.channel !== undefined,
      singleClickEdit: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: tactics,
        keyCreator: (params: KeyCreatorParams) => params.value.id,
        valueFormatter: (params: ValueFormatterParams) => params.value.name
      }
    },
    {
      field: 'unit_price_type',
      headerName: 'Unit Price Type',
      keyCreator: params => params.value.id,
      comparator: (a, b) => a.name.localeCompare(b.name),
      minWidth: 200,
      cellStyle: ({ data }) => cellStyle(data, 'unit_price_type'),
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: (params: any) =>
        ({
          values:
            params.data?.channel && params.data?.tactic
              ? unitPriceTypesForChannel(channels, params.data.channel, params.data.tactic)
              : [],
          formatValue: value => value && value.name
        }) as IRichCellEditorParams<LineItemTableData, UnitPriceType>,
      onCellValueChanged: (update: NewValueParams<LineItemTableData, UnitPriceType>) => {
        updateUnitPriceType(update, channels);
      },
      cellRenderer: cellRendererCmp('unit_price_type', 'Select Unit Price Type'),
      editable: ({ data }) => data?.tactic !== undefined,
      singleClickEdit: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: unitPriceTypes,
        keyCreator: (params: KeyCreatorParams) => params.value.id,
        valueFormatter: (params: ValueFormatterParams) => params.value.name
      }
    },
    {
      field: 'unit_price',
      headerName: 'Unit Price',
      cellDataType: 'number',
      editable: ({ data }) => data?.unit_price_type !== undefined,
      tooltipComponent: UnitPriceTooltip,
      tooltipComponentParams: { channels },
      tooltipField: 'unit_price',
      cellEditor: UnitPriceEditor,
      onCellValueChanged: updateUnitPrice,
      cellRenderer: (params: CellRendererProps<number>) => (
        <UnitPriceCellRenderer channels={channels} {...params} />
      ),
      valueSetter: params => {
        if (isZeroValue(params.newValue)) {
          return false;
        }
        params.data.unit_price = params.newValue;
        return true;
      }
    },
    {
      field: 'target_margin',
      headerName: 'Target Margin',
      cellDataType: 'number',
      editable: ({ data }) => data?.unit_price_type !== undefined,
      onCellValueChanged: updateTargetMargin,
      tooltipComponent: TargetMarginTooltip,
      tooltipComponentParams: { channels },
      tooltipField: 'target_margin',
      cellEditor: TargetMarginCellEditor,
      cellRenderer: (params: CellRendererProps<number>) => (
        <TargetMarginCellRenderer channels={channels} {...params} />
      )
    },
    {
      field: 'estimated_units',
      headerName: 'Estimated Units',
      cellDataType: 'number',
      cellRenderer: EstimatedUnitsRenderer
    },
    {
      field: 'unit_cost',
      headerName: 'Unit Cost',
      cellRenderer: UnitCostsRenderer
    },
    {
      field: 'media_budget',
      headerName: 'Media Budget',
      cellRenderer: MediaBudgetRenderer
    },
    {
      field: 'geo',
      cellStyle: ({ data }) => cellStyle(data, 'geo'),
      editable: true,
      onCellValueChanged: updateGeo,
      cellRenderer: cellRendererCmp('geo', 'Set Geo')
    },
    {
      field: 'targeting',
      cellStyle: ({ data }) => cellStyle(data, 'targeting'),
      editable: true,
      onCellValueChanged: updateTargeting,
      cellRenderer: cellRendererCmp('targeting', 'Set Targeting')
    },
    {
      field: 'audience',
      cellStyle: ({ data }) => cellStyle(data, 'audience'),
      editable: true,
      onCellValueChanged: updateAudience,
      cellRenderer: cellRendererCmp('audience', 'Set Audience')
    },
    {
      field: 'ad_formats',
      headerName: 'Ad Formats',
      cellStyle: ({ data }) => cellStyle(data, 'ad_formats'),
      editable: true,
      onCellValueChanged: updateAdFormats,
      cellRenderer: cellRendererCmp('ad_formats', 'Set Ad Formats')
    },
    {
      field: 'media_platforms',
      headerName: 'Media Platforms',
      cellStyle: ({ data }) => cellStyle(data, 'media_platforms'),
      editable: ({ data }) => data?.unit_price_type !== undefined,
      onCellValueChanged: updatePlatforms,
      cellRenderer: cellRendererCmp('media_platforms', 'Set Platform'),
      keyCreator: (params: KeyCreatorParams) => params.value.id,
      cellEditorSelector: ({ data }) => ({
        component: 'agRichSelectCellEditor',
        params: {
          multiSelect: true,
          suppressMultiSelectPillRenderer: true,
          cellRenderer: PlatformCellRenderer,
          values: platformValues(channels, data),
          formatValue: (value: Platform | undefined | null) => value?.name || '-'
        }
      })
    },
    {
      cellDataType: 'date',
      editable: true,
      field: 'start_date',
      headerName: 'Start date',
      cellEditorParams: ({ data }: { data?: LineItemTableData }) => ({
        min: new UTCDate('2015-01-01'),
        max: data?.end_date ? data.end_date : new UTCDate('2070-01-01')
      }),
      valueFormatter: params => formatDate(params.data?.start_date),
      cellStyle: ({ data }) => cellStyle(data, 'start_date'),
      onCellValueChanged: updateStartDate
    },
    {
      cellDataType: 'date',
      editable: true,
      field: 'end_date',
      headerName: 'End date',
      cellEditorParams: ({ data }: { data?: LineItemTableData }) => {
        return {
          min: data?.start_date ? data.start_date : new Date('2015-01-02'),
          max: new Date('2070-01-01')
        };
      },
      valueFormatter: params => formatDate(params.data?.end_date),
      cellStyle: ({ data }) => cellStyle(data, 'end_date'),
      onCellValueChanged: updateEndDate
    },
    {
      field: 'pacing_type',
      headerName: 'Pacing Type',
      editable: true,
      cellStyle: ({ data }) => cellStyle(data, 'pacing_type'),
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: ['lifetime', 'monthly', 'custom'], valueListGap: 10 },
      onCellValueChanged: updatePacingType,
      filter: 'agSetColumnFilter',
      filterParams: { values: ['lifetime', 'monthly', 'custom'] }
    },
    {
      field: 'pacing_graph',
      headerName: 'Pacing Details',
      minWidth: 200,
      cellStyle: ({ data }) => pacingGraphCellStyle(data),
      cellRendererParams: { sparklineOptions: { type: 'area' } },
      cellRendererSelector: ({ data }) => {
        if (data?.pacing_details?.blocks.length === 0) return { component: EmptyPacingDetails };
        if (data?.pacing_details) return { component: 'agSparklineCellRenderer' };
        else return { component: NullPacingDetails };
      },
      onCellClicked: event => {
        if (event.data?.id && event.data?.pacing_type === 'custom')
          useStore.getState().setLineItemPacing({
            lineItemId: event.data.id,
            open: true
          });
      }
    },
    {
      field: 'media_traders',
      headerName: 'Media Traders',
      editable: true,
      cellStyle: ({ data }) => cellStyle(data, 'media_traders'),
      valueFormatter: params => {
        return params.data?.media_traders?.map(mt => mt.name).join(',') || '';
      },
      comparator: (a, b) => {
        if (a.length === 0 && b.length === 0) return 0;
        if (a.length === 0) return -1;
        if (b.length === 0) return 1;
        return a[0].name.localeCompare(b[0].name);
      },
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: mediaTraders || [],
        valueListGap: 10,
        formatValue: formatUser,
        multiSelect: true,
        searchType: 'matchAny',
        filterList: true,
        highlightMatch: true,
        suppressMultiSelectPillRenderer: true
      },
      onCellValueChanged: updateMediaTraders
    },
    {
      field: 'actions',
      cellRenderer: LineItemActions,
      cellStyle: { padding: 0, border: 'none' },
      minWidth: 93,
      headerName: '',
      resizable: false,
      pinned: 'right',
      suppressHeaderFilterButton: true,
      suppressHeaderContextMenu: true,
      suppressMovable: true
    }
  ];
}

function formatUser(user: User | User[]) {
  if (Array.isArray(user)) {
    return user?.map(u => u.name).join(', ') || '';
  }
  return user?.name || '';
}

function generateGraph(lineItem: CombinedLineItem): number[] {
  const { pacing_details } = lineItem;
  if (
    !pacing_details ||
    pacing_details.blocks.length === 0 ||
    !lineItem.start_date ||
    !lineItem.end_date
  )
    return [];
  return getCumulativeSpend(lineItem.start_date, lineItem.end_date, pacing_details.blocks).map(
    day => day.cumulativeTotal
  );
}

function cellStyle(data: CombinedLineItem | undefined, key: keyof PartialLineItem): CellStyle {
  if (!data || data.state.type === 'unchanged') return {};
  if (data.state.type === 'new' || data.state.dirty[key]) return editedStyle;
  return {};
}

function pacingGraphCellStyle(data: CombinedLineItem | undefined): CellStyle {
  return !data || !isLineItemValid(data)
    ? { backgroundColor: 'rgba(255, 240, 233, 0.5)', borderBottom: '1px solid red' }
    : {};
}

function cellRendererCmp(key: keyof LineItemTableData, label: string) {
  return (props: CustomCellRendererProps<LineItemTableData>) => {
    const disabled =
      (key === 'tactic' && !props.data?.channel) ||
      (key === 'unit_price_type' && !props.data?.tactic) ||
      (key === 'unit_price' && !props.data?.unit_price_type);

    return (
      getValueString(props.data?.[key]) || (
        <div className={`${disabled ? 'text-gray-400' : 'text-blue-500'}`}>{label}</div>
      )
    );
  };
}

function platformValues(channels: Channel[], data: LineItemTableData) {
  return data.channel !== undefined &&
    data.tactic !== undefined &&
    data.unit_price_type !== undefined
    ? platformsForTactics(channels, data.channel, data.tactic)
    : [];
}

const editedStyle = {
  borderBottom: '1px solid #F59460',
  backgroundColor: 'rgba(255, 240, 233, 0.5)'
};

function isZeroValue(value: number | null) {
  return value === 0 || value === null;
}

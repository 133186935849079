import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { client } from '../../utils/trpc-client';
import { formatMoney } from 'shared/src/money-utils';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  ModuleRegistry,
  SortModelItem,
  StatusPanelDef,
  ValueFormatterParams
} from '@ag-grid-community/core';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { AgGridReact, CustomCellRendererProps } from '@ag-grid-community/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '../shared/page-title';
import {
  MediaBuyListRequest,
  MediaBuysFilterTypes,
  MediaBuySort,
  MediaBuySortColumn,
  mediaBuySortColumns,
  MediaBuyTableRow
} from 'shared/src/media-buy-types';
import { SearchRow } from '../../components/search-bar';
import { showErrorToast } from '../../components/error-toast';
import { getPlatformName, MEDIA_PLATFORM_IDS } from '../../../../shared/src/media-platforms';
import { AllMediaBuysIcon } from '../../components/nav-icons';
import posthog from 'posthog-js';
import { SyncMediaBuysButton } from './sync-media-buys-button';
import { TotalStatusPanel } from '../shared/totals-status-panel';

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  MenuModule,
  SetFilterModule,
  StatusBarModule,
  FiltersToolPanelModule
]);

export function MediaBuys() {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string>('');
  const [gridApi, setGridApi] = useState<GridApi<MediaBuyTableRow> | null>(null);
  const [columnDefs] = useState<ColDef<MediaBuyTableRow>[]>([
    {
      field: 'id',
      cellRenderer: (props: CustomCellRendererProps) => {
        if (props.value !== undefined) {
          return props.value;
        } else {
          return <FontAwesomeIcon icon={faSpinner} className="animate-rotate text-blue-500" />;
        }
      },
      suppressHeaderMenuButton: true
    },
    {
      field: 'name',
      headerName: 'Name',
      suppressHeaderMenuButton: true
    },
    {
      field: 'media_platform_id',
      headerName: 'Media Platform',
      suppressHeaderMenuButton: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: Object.keys(MEDIA_PLATFORM_IDS),
        valueFormatter: (prop: ValueFormatterParams<MediaBuyTableRow>) =>
          getPlatformName(Number(prop.value))
      },
      valueFormatter: prop => getPlatformName(prop.data?.media_platform_id)
    },
    {
      field: 'budget',
      headerName: 'Platform Budget',
      valueFormatter: ({ value }) => (value ? formatMoney(value) : ''),
      suppressHeaderMenuButton: true,
      filter: 'agNumberColumnFilter',
      filterParams: {
        allowedCharPattern: '\\d\\-\\,',
        filterOptions: ['equals', 'greaterThan', 'lessThan', 'inRange'],
        maxNumConditions: 1
      }
    },
    {
      field: 'start_date',
      headerName: 'Start Date',
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset'],
        filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
        maxNumConditions: 1
      },
      suppressHeaderMenuButton: true
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      filter: 'agDateColumnFilter',
      suppressHeaderMenuButton: true,
      filterParams: {
        buttons: ['apply', 'reset'],
        filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
        maxNumConditions: 1
      }
    }
  ]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100
      // sortable: false
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      gridApi.setGridOption('serverSideDatasource', {
        getRows: async params => {
          const parsedFilters = MediaBuysFilterTypes.safeParse(params.request.filterModel);
          if (!parsedFilters.success) {
            console.error('Failed to parse media buys filter model', parsedFilters.error);
            return;
          }
          try {
            const { media_buys, total } = await client.mediaBuys.query({
              page: params.request.endRow ? Math.ceil(params.request.endRow / 50) : 1,
              sort: getCurrentSort(params.request.sortModel),
              filters: parsedFilters.data,
              ...(searchText && { search: searchText })
            });
            params.success({ rowData: media_buys, rowCount: total });
          } catch (error) {
            console.error('Failed to fetch media buys', error);
            showErrorToast();
            return;
          }
        }
      });
    }
  }, [gridApi, searchText]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    params.api.setFilterModel(defaultFilters());
    setGridApi(params.api);
  }, []);

  const statusBar = useMemo<{
    statusPanels: StatusPanelDef[];
  }>(() => {
    return {
      statusPanels: [{ statusPanel: TotalStatusPanel }]
    };
  }, []);

  return (
    <div className="flex h-full w-full flex-col p-12">
      <PageTitle title="Bravo | Media Buys" />
      <SearchRow
        icon={<AllMediaBuysIcon />}
        title="All Media Buys"
        placeholder="Search By Media Buy"
        submitSearch={setSearchText}
      />
      {posthog.isFeatureEnabled('sync-media-buys') && <SyncMediaBuysButton />}
      <div className="ag-theme-quartz" style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowBuffer={0}
          rowModelType={'serverSide'}
          cacheBlockSize={50}
          cacheOverflowSize={2}
          isExternalFilterPresent={() => true}
          infiniteInitialRowCount={100}
          maxBlocksInCache={10}
          sideBar="filters"
          onGridReady={onGridReady}
          statusBar={statusBar}
          onRowClicked={event =>
            event.data?.id && navigate(`/campaigns/${event.data.id}/strategy/performance`)
          }
        />
      </div>
    </div>
  );
}

function getCurrentSort(sortModel: SortModelItem[]): MediaBuySort | undefined {
  if (sortModel.length === 0) return undefined;
  const { sort, colId } = sortModel[0];
  if (mediaBuySortColumns.includes(colId)) {
    // TODO[mk]: properly refine type
    return { column: colId as MediaBuySortColumn, direction: sort };
  } else return undefined;
}

function defaultFilters(): MediaBuyListRequest {
  return { page: 1, filters: {} };
}

import React from 'react';

type ButtonProps = {
  onClick: () => unknown;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
};

export function Button(props: ButtonProps) {
  return (
    <BaseButton
      {...props}
      className={`bg-blue-600 text-white hover:bg-blue-700 focus:ring-sky-500 disabled:bg-sky-300 ${props.className}`}
    />
  );
}

export function SecondaryButton(props: ButtonProps) {
  return (
    <BaseButton
      {...props}
      className={`bg-white text-gray-600 hover:bg-gray-100 focus:ring-sky-500 disabled:text-gray-200 ${props.className}`}
    />
  );
}

export function AlertButton(props: ButtonProps) {
  return (
    <BaseButton
      {...props}
      className={`bg-red-600 text-white hover:bg-red-400 focus:ring-red-400 active:bg-red-800 disabled:bg-gray-400 disabled:text-gray-200 ${props.className}`}
    />
  );
}

type BaseButtonProps = ButtonProps & { className: string };

function BaseButton({ children, disabled, onClick, className }: BaseButtonProps) {
  return (
    <button
      disabled={disabled}
      type="button"
      className={`inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${className}`}
      onClick={onClick}>
      {children}
    </button>
  );
}

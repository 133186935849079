import React from 'react';
import { CustomCellRendererProps, CustomHeaderProps } from '@ag-grid-community/react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { formatMetricPercentage } from '../../components/hero-metrics';
import { formatCurrency } from '../../components/table-utils';
import { getPerformanceBackground, getPerformanceColor } from '../../utils/color-utils';
import { CalculatedValue } from '../line-items/line-item-table-cell-components';
import { MediaBuysTableData } from './media-buys-table';
import { getPlatformName } from 'shared/src/media-platforms';

export type CellRendererProps<T> = CustomCellRendererProps<MediaBuysTableData, T>;
type HeaderRendererProps<T> = CustomHeaderProps<MediaBuysTableData, T>;

export function HeaderComponent({ displayName }: HeaderRendererProps<string>) {
  return <div className="capitalize">{displayName}</div>;
}

export function ViewButtonCellRenderer({
  colorMap,
  data,
  node
}: CellRendererProps<string> & { colorMap: Record<string, string> }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  if (node.group || !data) return null;

  const color = colorMap[data.media_buy.id];

  return (
    <div className="flex h-full items-center">
      <div className="mr-2 h-[24px] w-[6px] rounded-full" style={{ background: color }} />
      <button
        className="flex h-[28px] items-center justify-center rounded-2xl border-2 border-gray-400 bg-gray-100 px-4 hover:bg-gray-200"
        onClick={() => navigate(`${pathname}/../mediabuys/${data.media_buy.id}/overview`)}>
        View
      </button>
    </div>
  );
}

export function PlatformRenderer({
  data,
  node,
  value
}: CustomCellRendererProps<MediaBuysTableData>) {
  if (node.group) {
    return <TotalsRowCell value={value} unit="platforms" />;
  }
  return getPlatformName(data?.media_buy.media_platform_entity?.media_platform_id);
}

export function MetricCellRenderer(props: CellRendererProps<number>) {
  const { node, value, column, data } = props;

  if (value == null || column == null || (!node.group && data == null)) return '--';

  const colorValue =
    column.getColId() === 'margin'
      ? node.group
        ? node.aggData.marginPerformance
        : data?.marginPerformance
      : value;
  const background = getPerformanceBackground(colorValue);
  const color = getPerformanceColor(colorValue);

  return (
    <div className={clsx('h-full p-1')}>
      <div
        className={clsx(
          'flex h-full w-full items-center justify-center overflow-x-clip rounded  px-2',
          node.group && 'font-semibold'
        )}
        style={{ background, color }}>
        {formatMetricPercentage(value)}
      </div>
    </div>
  );
}

export function MediaBuyLinkBudgetRenderer({
  node,
  valueFormatted,
  lineItemBudget,
  value
}: CellRendererProps<number> & { lineItemBudget: number }) {
  if (node.group) {
    return <TotalsRowCell value={valueFormatted} isAlarm={value !== lineItemBudget} />;
  }

  return valueFormatted;
}

export function TargetUnitCostRenderer({ node, valueFormatted }: CellRendererProps<number>) {
  if (node.group) {
    return <TotalsRowCell value={valueFormatted} />;
  }

  return valueFormatted;
}

export function MediaBuyTargetMarginRenderer({ node, valueFormatted }: CellRendererProps<number>) {
  if (node.group) {
    return <TotalsRowCell value={valueFormatted} />;
  }

  return <CalculatedValue disabled={false} value={valueFormatted || '0'} />;
}

export function TargetUnitsRenderer({ node, valueFormatted }: CellRendererProps<number>) {
  if (node.group) {
    return <TotalsRowCell value={valueFormatted} />;
  }

  return <CalculatedValue disabled={false} value={valueFormatted || '0'} />;
}

export function DeliveredUnitsRenderer({ node, valueFormatted }: CellRendererProps<string>) {
  if (node.group) {
    return <TotalsRowCell value={valueFormatted} />;
  }

  return valueFormatted;
}

export function MediaSpendRenderer({ node, value }: CellRendererProps<number>) {
  if (node.group) {
    return <TotalsRowCell value={formatCurrency(value || 0)} />;
  }

  return formatCurrency(value || 0);
}

export function PlatformBuyNameRenderer({ node, value }: CellRendererProps<string>) {
  if (node.group) return null;

  const lastPipeIdx = value ? value.lastIndexOf('|') : -1;
  const firstPart = value?.substring(0, lastPipeIdx);
  const lastPart = value?.substring(lastPipeIdx);

  return (
    <div className="flex h-full w-full items-center py-1">
      <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">{firstPart}</div>
      <div>{lastPart}</div>
    </div>
  );
}

export function TotalsRowCell({
  value,
  unit,
  isAlarm
}: {
  value: string | null | undefined;
  unit?: string;
  isAlarm?: boolean;
}) {
  return (
    <div className={clsx(isAlarm && 'text-red-600')}>
      <span className="font-semibold">{value || '--'}</span>
      {unit && <span>{` ${unit}`}</span>}
    </div>
  );
}

import React, { useMemo, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { CombinedLineItem } from '../../store/strategy-combiner';
import { PendingChangesButton } from '../pending-changes/pending-changes-button';
import { Campaign } from 'shared/src/types';
import { faChartLine, faGaugeHigh, faGears } from '@fortawesome/pro-light-svg-icons';
import { useStrategyContext } from './strategy-context';
import {
  ExpandableSidebarSection,
  PageContentSection,
  PageSection,
  TopSection
} from '../../components/layout';
import { NavItem } from '../../components/navitem';
import { NewMediaBuyButton } from '../media-buys/new-media-buy-modal';
import { MediaBuysTable } from '../media-buys/media-buys-table';
import { ViewLineItemPacing } from './rail/view-line-item-pacing';
import { useLineItemMetrics } from '../media-buys/use-media-buy-metrics';
import { calcLineItemMetrics, calcMediaBuyMetrics } from '../../metrics/calculate-metrics';
import { MediaBuyHeroMetrics } from '../media-buys/media-buy-hero-metrics';
import { MediaBuyGraph } from '../media-buys/media-buy-graph';
import { DisplayMode, DisplayModeSelector } from '../../components/charts/display-mode-selector';
import {
  ChartType,
  ChartTypeSelector,
  Granularity,
  GranularitySelector
} from '../../components/charts/chart-type-selector';
import { convertToLineItem, isCompleted } from 'shared/src/line-item-types';
import { PerformanceRail } from '../shared/performance-rail';
import { DetailsRail } from '../shared/details-rail';
import { lineItemTitle, PageTitle } from '../shared/page-title';
import { LineItemBreadcrumbs } from '../shared/breadcrumbs';

export function LineItemPage() {
  const { strategy, campaign } = useStrategyContext();
  const { lineItemId } = useParams();
  const navigate = useNavigate();
  const lineItem = strategy.line_items.find(li => li.id === lineItemId);

  if (!lineItem) {
    // If a new line-item change is rejected while on the line-item-page then lineItem will not be set.
    // We handle this here.
    navigate(`/campaigns/${campaign?.id}/strategy/performance`);
    console.log(`Lineitem is not set, redirecting back to campaign strategy`);
    return null;
  }

  return (
    <div className="flex w-full flex-col">
      <TopSection>
        <LineItemBreadcrumbs campaign={campaign} name={lineItem.name} />
        <div className="flex-1" />
        <div className="flex items-end">
          <NavItem to="overview" label="Overview" />
        </div>
      </TopSection>
      <PendingChangesButton />
      <Routes>
        <Route
          path="overview"
          element={<LineItemOverview campaign={campaign} lineItem={lineItem} />}
        />
      </Routes>
    </div>
  );
}

type LineItemOverviewProps = {
  campaign: Campaign | undefined;
  lineItem: CombinedLineItem;
};

function LineItemOverview({ campaign, lineItem }: LineItemOverviewProps) {
  const [granularity, setGranularity] = useState<Granularity>('day');
  const [displayMode, setDisplayMode] = useState<DisplayMode>('discrete');
  const [chartType, setChartType] = useState<ChartType>('spend');
  const { data } = useLineItemMetrics(lineItem.id, granularity);
  const lineItemMetrics = useMemo(() => calcLineItemMetrics(data, lineItem), [data, lineItem]);
  const mediaBuyMetrics = useMemo(
    () =>
      lineItem.media_buys
        .map(mediaBuy => calcMediaBuyMetrics(data, lineItem, mediaBuy))
        .filter(metrics => metrics != null),
    [data, lineItem]
  );
  const [colorMap, setColorMap] = useState<Record<string, string>>({});
  const completeLineItem = isCompleted(lineItem) ? convertToLineItem(lineItem) : null;

  return (
    <PageSection>
      <PageTitle title={lineItemTitle(lineItem.name)} />
      <PageContentSection>
        {lineItemMetrics && <MediaBuyHeroMetrics metrics={lineItemMetrics} />}
        <div className="flex flex-grow flex-col">
          <div className="flex items-center">
            <ChartTypeSelector selected={chartType} setSelected={setChartType} />
            <GranularitySelector granularity={granularity} setGranularity={setGranularity} />
            <div className="flex-1" />
            <DisplayModeSelector displayMode={displayMode} setDisplayMode={setDisplayMode} />
          </div>
          <MediaBuyGraph
            data={data}
            lineItem={lineItem}
            mediaBuys={lineItem.media_buys}
            granularity={granularity}
            displayMode={displayMode}
            chartType={chartType}
            setColorMap={setColorMap}
          />
        </div>
        <div className="flex shrink flex-col gap-2">
          <div className="flex h-[50px] items-center justify-between">
            <div className="text-lg font-bold">Media Plans</div>
            <NewMediaBuyButton lineItem={lineItem} campaign={campaign} />
          </div>
          <MediaBuysTable
            mediaBuys={lineItem.media_buys}
            metrics={mediaBuyMetrics}
            colorMap={colorMap}
            unitPriceType={lineItem.unit_price_type}
            lineItemBudget={lineItemMetrics?.budget}
          />
        </div>
      </PageContentSection>
      <ExpandableSidebarSection
        sections={[
          ...(completeLineItem && lineItemMetrics
            ? [
                {
                  name: 'execution',
                  header: 'Execution Instructions',
                  icon: faGears,
                  render: (setExpanded: (expanded: string) => void) => (
                    <DetailsRail
                      campaign={campaign}
                      lineItem={completeLineItem}
                      metrics={lineItemMetrics}
                      setExpanded={setExpanded}
                    />
                  )
                },
                {
                  name: 'performance',
                  header: 'Line Item Performance',
                  icon: faGaugeHigh,
                  render: () => (
                    <PerformanceRail lineItem={completeLineItem} metrics={lineItemMetrics} />
                  )
                }
              ]
            : []),
          ...(completeLineItem && completeLineItem.pacing_type === 'custom'
            ? [
                {
                  name: 'pacing',
                  header: 'Pacing Schedule',
                  icon: faChartLine,
                  render: () => <ViewLineItemPacing lineItem={completeLineItem} />
                }
              ]
            : [])
        ]}
      />
    </PageSection>
  );
}
